@import"https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&amp;display=swap";

:root {
    color-scheme: light;
    --font-primary: "Kanit", sans-serif;
    --font-secondary: "Kanit", sans-serif;
    --color-primary: #0068cc;
    --color-primary-light: rgba(0, 104, 204, 0.15);
    --color-primary-light-2: rgba(0, 104, 204, 0.08);
    --color-primary-text: #ffffff;
    --text-primary: #150801;;
    --text-secondary: #6a6a6a;
    --text-light: #b7b7b7;
    --color-border: #dcdada;
    --border-primary: rgba(234, 125, 24, 0.4);
    --border-light: rgba(255, 255, 255, .2);
    --color-white: #fff;
    --color-gray-1: #eff2f7;
    --color-dark: #101010;
    --color-dark-2: #2d3134;
    --bg-light: #f7f6f5;
    --site-bg: #ffffff;
    --card-bg: #fff;
    --topbar-bg: #fff;
    --sidebar-bg: #fff;
    --color-success: rgb(10, 179, 156);
    --color-success-light: rgba(10, 179, 156, 0.12);
    --success-border: rgba(10, 179, 156, 0.4);
    --color-danger: rgb(255, 20, 35);
    --color-danger-light: rgba(240, 101, 72, 0.12);
    --danger-border: rgba(240, 101, 72, 0.4);
    --color-warning: rgb(259, 165, 81);
    --color-warning-light: rgba(247, 184, 75, 0.15);
    --color-info: rgb(45, 140, 210);
    --color-info-light: rgba(45, 140, 210, 0.15);
    --color-green: rgb(10, 179, 58);
    --color-green-light: rgba(10, 179, 58, 0.1);
    --color-purple: rgb(105, 4, 207);
    --color-purple-light: rgba(105, 4, 207, 0.1);
    --color-blue: rgb(4, 163, 207);
    --color-blue-light: rgba(4, 163, 207, 0.1)
}

* {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: var(--text-primary);
    margin-bottom: 0;
    font-family: var(--font-secondary);
    font-weight: 500;
    line-height: 1.2;
}

h4 {
    font-size: 24px
}

p {
    color: var(--text-secondary)
}

r a,
a:active,
a:visited,
a:link {
    text-decoration: none
}

ul,
ol,
li {
    padding: 0;
    margin: 0;
    list-style-type: none
}

button{
    outline: none;
    background-color: inherit;
}

body {
    display: flex;
    flex-direction: column;
    line-height: 1.65;
    font-family: var(--font-primary);
    font-weight: 300;
    color: var(--text-secondary);
    font-size: 16px
}

@media(max-width: 767px) {
    body {
        font-size: 15px
    }
}

img {
    max-width: 100%;
    height: auto;
    display: block
}

button,
[type=button],
[type=reset],
[type=submit] {
    -webkit-appearance: button
}

.cursor-pointer {
    cursor: pointer
}

.pt-110 {
    padding-top: 110px
}

@media(max-width: 767px) {
    .pt-110 {
        padding-top: 80px
    }
}

.pb-110 {
    padding-bottom: 110px
}

@media(max-width: 767px) {
    .pb-110 {
        padding-bottom: 80px
    }
}

.pt-90 {
    padding-top: 90px
}
@media(max-width: 767px) {
    .pt-90 {
        padding-top: 60px
    }
}
.pt-80 {
    padding-top: 80px
}
@media(max-width: 767px) {
    .pt-90 {
        padding-top: 60px
    }
}

.pb-90 {
    padding-bottom: 90px
}
@media(max-width: 767px) {
    .pb-90 {
        padding-bottom: 60px
    }
}
.fs-15{
    font-size: 15px !important;
}
.fs-16{
    font-size: 16px !important;
}
.fs-13{
    font-size: 13px;
}

.fs-18{
    font-size: 18px;
    font-weight: 400;
}
@media (max-width: 768px){
    .fs-18{
        font-size: 16px;
    }
}

.padding-left-right{
    padding-left: 7%;
    padding-right: 7%;
}

.color--primary{
    color: var(--color-primary);
}
.text--dark{
    color: var(--text-primary);
}
.i-badge {
    border-radius: 0px;
    display: inline-block;
    padding: 5px 10px;
    font-size: 15px;
    font-weight: 400;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    transition: .3s ease;
    transition: .3s ease
}

.i-badge.capsuled {
    border-radius: 50px
}

.i-badge.badge--success {
    color: var(--color-success);
    background-color: var(--color-success-light);
    border: 1px solid var(--success-border)
}

.i-badge.badge--success:hover {
    color: var(--color-white);
    background-color: var(--color-success)
}

.i-badge.badge--danger {
    background-color: var(--color-danger-light);
    color: var(--color-danger);
    border: 1px solid var(--danger-border)
}

.i-badge.badge--danger:hover {
    color: var(--color-white);
    background-color: var(--color-danger)
}

.simplebar-scrollbar:before {
    background: rgba(90, 90, 90, .6117647059)
}

button {
    outline: none;
    border: none
}

.i-btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-family: var(--font-primary);
    font-weight: 400;
    line-height: 1;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    border-radius: 10px;
    transition: .35s;
    line-height: 1;
    white-space: nowrap
}

.i-btn.capsuled {
    border-radius: 50px
}

.i-btn.btn--primary {
    background-color: var(--color-primary);
    color: var(--color-white);
    transition: all .5s ease-in-out;
    overflow: hidden;
    position: relative;
    z-index: 1
}

.i-btn.btn--primary:hover::before {
    width: 100%;
    opacity: 1
}

.i-btn.btn--primary:hover::after {
    width: 100%;
    opacity: 1
}

.i-btn.btn--primary::before {
    content: "";
    display: block;
    width: 100%;
    height: 50%;
    position: absolute;
    left: 0;
    top: 0px;
    background: var(--color-dark);
    width: 30%;
    transition: .5s ease;
    z-index: -1;
    opacity: 0
}

.i-btn.btn--primary::after {
    content: "";
    display: block;
    width: 100%;
    height: 50%;
    position: absolute;
    right: 0;
    bottom: 0px;
    background: var(--color-dark);
    width: 30%;
    transition: .5s ease;
    z-index: -1;
    opacity: 0
}

/* button dark */
.i-btn.btn--dark {
    background-color: var(--color-dark);
    color: var(--color-white);
    transition: all .5s ease-in-out;
    overflow: hidden;
    position: relative;
    z-index: 1
}

.i-btn.btn--dark:hover::before {
    width: 100%;
    opacity: 1
}

.i-btn.btn--dark:hover::after {
    width: 100%;
    opacity: 1
}

.i-btn.btn--dark::before {
    content: "";
    display: block;
    width: 100%;
    height: 50%;
    position: absolute;
    left: 0;
    top: 0px;
    background: var(--color-primary);
    width: 30%;
    transition: .5s ease;
    z-index: -1;
    opacity: 0
}

.i-btn.btn--dark::after {
    content: "";
    display: block;
    width: 100%;
    height: 50%;
    position: absolute;
    right: 0;
    bottom: 0px;
    background: var(--color-primary);
    width: 30%;
    transition: .5s ease;
    z-index: -1;
    opacity: 0
}

/* button outline start */
.i-btn.btn--outline {
    color: var(--text-primary);
    border: 1px solid var(--text-primary);
    background-color: #fff;
    position: relative;
    z-index: 1;
    overflow: hidden
}

.i-btn.btn--outline:hover {
    color: var(--color-white);
    border-color: var(--color-dark);
}

.i-btn.btn--outline:hover::before {
    width: 100%;
    opacity: 1;
}

.i-btn.btn--outline:hover::after {
    width: 100%;
    opacity: 1;
}

.i-btn.btn--outline::before {
    content: "";
    display: block;
    width: 100%;
    height: 50%;
    position: absolute;
    left: 0;
    top: 0px;
    background: var(--color-dark);
    width: 0px;
    transition: .4s ease;
    z-index: -1;
    opacity: 0;
}

.i-btn.btn--outline::after {
    content: "";
    display: block;
    width: 100%;
    height: 50%;
    position: absolute;
    right: 0;
    bottom: 0px;
    background: var(--color-dark);
    width: 0px;
    transition: .4s ease;
    z-index: -1;
    opacity: 0;
}

.i-btn.btn--primary-outline {
    color: var(--color-primary);
    border: 1px solid var(--color-primary-light);
    background-color: rgba(0, 0, 0, 0);
    position: relative;
    z-index: 1;
    overflow: hidden
}

.i-btn.btn--primary-outline:hover {
    color: var(--color-white);
    border: 1px solid var(--color-dark)
}

.i-btn.btn--primary-outline:hover::before {
    width: 100%
}

.i-btn.btn--primary-outline:hover::after {
    width: 100%
}

.i-btn.btn--primary-outline::before {
    content: "";
    display: block;
    width: 100%;
    height: 50%;
    position: absolute;
    left: 0;
    top: 0px;
    background: var(--color-dark);
    width: 0px;
    transition: .4s ease;
    z-index: -1
}

.i-btn.btn--primary-outline::after {
    content: "";
    display: block;
    width: 100%;
    height: 50%;
    position: absolute;
    right: 0;
    bottom: 0px;
    background: var(--color-dark);
    width: 0px;
    transition: .4s ease;
    z-index: -1
}

.i-btn.btn--secondary {
    background-color: var(--text-primary);
    color: var(--color-white)
}

.i-btn.btn--success {
    background-color: var(--color-success);
    color: var(--color-white);
    transition: all .5s ease-in-out;
    position: relative;
    overflow: hidden;
    z-index: 1
}

.i-btn.btn--success:hover::before {
    width: 100%;
    opacity: 1
}

.i-btn.btn--success:hover::after {
    width: 100%;
    opacity: 1
}

.i-btn.btn--success::before {
    content: "";
    display: block;
    width: 50%;
    height: 1000%;
    position: absolute;
    left: 0;
    top: 0px;
    background: var(--color-dark);
    width: 0%;
    transition: .5s ease;
    z-index: -1;
    opacity: 0
}

.i-btn.btn--success::after {
    content: "";
    display: block;
    width: 50%;
    height: 100%;
    position: absolute;
    right: 0;
    bottom: 0px;
    background: var(--color-dark);
    width: 0%;
    transition: .5s ease;
    z-index: -1;
    opacity: 0
}

.i-btn.btn--danger {
    background-color: var(--color-danger);
    color: var(--color-white);
    transition: all .5s ease-in-out;
    position: relative;
    overflow: hidden;
    z-index: 1
}

.i-btn.btn--danger:hover::before {
    width: 100%;
    opacity: 1
}

.i-btn.btn--danger:hover::after {
    width: 100%;
    opacity: 1
}

.i-btn.btn--danger::before {
    content: "";
    display: block;
    width: 50%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0px;
    background: var(--color-dark);
    width: 30%;
    transition: .5s ease;
    z-index: -1;
    opacity: 0
}

.i-btn.btn--danger::after {
    content: "";
    display: block;
    width: 50%;
    height: 100%;
    position: absolute;
    right: 0;
    bottom: 0px;
    background: var(--color-dark);
    width: 30%;
    transition: .5s ease;
    z-index: -1;
    opacity: 0
}

.i-btn.btn--sm {
    padding: 9px 15px;
    font-size: 14px
}

@media(max-width: 767px) {
    .i-btn.btn--sm {
        padding: 8px 12px
    }
}

.i-btn.btn--md {
    padding: 12px 24px;
    font-size: 16px
}

@media(max-width: 767px) {
    .i-btn.btn--md {
        padding: 10px 22px
    }
}

.i-btn.btn--lg {
    padding: 15px 30px;
    font-size: 18px
}

@media(max-width: 767px) {
    .i-btn.btn--lg {
        padding: 12px 20px
    }
}

.i-btn.capsuled {
    border-radius: 50px
}

.read-more-btn {
    color: var(--text-primary);
    font-weight: 600
}

.read-more-btn i {
    font-size: 16px;
    width: 40px;
    height: 40px;
    line-height: 40px;
    border-radius: 50%;
    background-color: var(--color-primary);
    text-align: center;
    margin-left: 15px;
    transition: .6s ease;
    color: var(--color-white);
    transform: rotate(0deg)
}

.read-more-btn:hover i {
    transform: rotate(45deg)
}

.full--width{
    padding-left: 5%;
    padding-right: 5%;
}

/* Margin */
.mb-5 {
    margin-bottom: 2.5rem !important
}

.mb-60 {
    margin-bottom: 60px
}

@media(max-width: 767px) {
    .mb-60 {
        margin-bottom: 45px
    }
}

.mb-90 {
    margin-bottom: 90px
}

@media(max-width: 767px) {
    .mb-90 {
        margin-bottom: 60px
    }
}

.mb-50 {
    margin-bottom: 50px
}

.mb-40 {
    margin-bottom: 40px
}

.mb-30 {
    margin-bottom: 30px
}

.mb-20 {
    margin-bottom: 20px
}

.mb-10 {
    margin-bottom: 10px
}

.mt-50 {
    margin-top: 50px
}

.mt-40 {
    margin-top: 40px
}

.mt-30 {
    margin-top: 30px
}

.mt-20 {
    margin-top: 20px
}

.mt-10 {
    margin-top: 10px
}

.up {
    color: var(--color-success) !important
}

.radius-50{
    border-radius: 50%;
}

.down {
    color: var(--color-danger) !important
}

.bg--light {
    background-color: var(--bg-light)
}
.text--light{
    color: var(--text-secondary);
}

.avatar--md{
    width: 35px;
    height: 35px;
    border-radius: 50%;
    overflow: hidden;

}

.section-title {
    position: relative;
    display: inline-block
}

.section-title.style-two h2 {
    color: var(--color-white)
}

.section-title.style-two p {
    color: var(--color-white);
    opacity: .75;
}

.section-title h2 {
    font-size: 50px;
    font-weight: 500;
    margin-bottom: 10px;
    margin-top: -10px;
    position: relative;
    display: inline-block
}

.section-title h2::before {
    content: "";
    position: absolute;
    left: -10px;
    top: -5px;
    width: 50px;
    height: 50px;
    background-color: var(--color-primary-light);
    border-radius: 50%;
    z-index: -1
}

@media(max-width: 991px) {
    .section-title h2::before {
        left: 0
    }
}

@media(max-width: 767px) {
    .section-title h2 {
        font-size: 30px
    }
}

@media(min-width: 768px)and (max-width: 991px) {
    .section-title h2 {
        font-size: 34px
    }
}

@media(min-width: 1200px)and (max-width: 1399px) {
    .section-title h2 {
        font-size: 40px
    }
}

.section-title P {
    margin-bottom: 0px;
    font-size: 16px;
    color: var(--text-secondary)
}
@media(max-width: 767px) {
    .section-title P {
        font-size: 15px;
    }
}

.i-container {
    max-width: 1600px !important;
    margin: 0 auto
}

.img-adjust {
    background-size: cover !important;
    background-repeat: no-repeat;
    background-position: center center
}

.modal-header .btn-close:focus{
    box-shadow: none;
}

.modal-content {
    background-color: var(--color-white);
}

.modal-content .modal-header {
    background-color: var(--color-primary-light-2);
    border-bottom: unset;
}

.modal-content {
    background-color: var(--color-white)
}

.modal-header{
    padding: 12px 20px;
}
.modal-body{
    padding: 20px 20px;
}

.offcanvas {
    background-color: var(--color-primary-light-2) !important;
    color: var(--text-secondary)
}

.offcanvas .offcanvas-header i {
    color: var(--color-white)
}

.closer-btn {
    width: 26px;
    height: 26px;
    border-radius: 50%;
    line-height: 1;
    font-size: 20px;
    background-color: rgba(0, 0, 0, 0)
}

.service-tab-wrapper .tab-pane {
    display: none
}

.service-tab-wrapper .tab-pane.active {
    display: block;
    animation: fadesmooth 1s
}

@keyframes fadesmooth {
    0% {
        opacity: 0
    }

    100% {
        opacity: 1
    }
}

.video-pluse {
    height: 40px;
    width: 40px;
    text-align: center;
    line-height: 50px;
    background: var(--color-white);
    color: var(--color-primary);
    border-radius: 50%;
    cursor: pointer;
    position: relative
}

.video-pluse span {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: var(--color-white);
    border-radius: 50%;
    display: inline-block;
    animation: video-pulse 3s linear infinite;
    animation-delay: calc(-0.5s*var(--i))
}

@keyframes video-pulse {
    0% {
        transform: scale(1);
        opacity: .5
    }

    100% {
        transform: scale(1.75);
        opacity: 0
    }
}

.video-pluse i {
    font-size: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: var(--color-primary)
}

.bg-color {
    background-color: #1d1d1d !important;
}
.bg-color-2{
    background-color: var(--color-dark-2) !important;
}
.bg-primary-light {
    background-color: var(--color-primary-light-2)
}

#magic-cursor {
    position: absolute;
    display: none;
    width: 25px;
    height: 25px;
    pointer-events: none;
    z-index: 99999;
    opacity: 0;
    padding: 20px
}

body.tt-magic-cursor #magic-cursor {
    display: block
}

#ball {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--color-white);
    pointer-events: none;
    border-style: solid;
    border-radius: 50%;
    background-color: var(--color-primary-light);
    border-color: var(--color-primary-light)
}

.cursor-style-two {
    border-radius: 0% !important;
    background-color: var(--color-white) !important;
    color: #000 !important;
    padding: 15px
}

#ball.ball-view {
    background-color: #000;
    font-size: 16px;
    font-weight: 500;
    color: var(--color-white);
    line-height: 1.2;
    text-align: center
}

#ball .ball-view-inner {
    padding: 0 0px;
    opacity: 0;
    visibility: hidden;
    transform: scale(0);
    font-size: 16px
}

#ball .ball-drag {
    position: absolute;
    display: block;
    width: 100%;
    padding: 0 0px;
    font-size: 15px;
    font-weight: 600;
    color: var(--color-white);
    line-height: 1.2;
    text-align: center;
    transition: .3s
}

#ball .ball-drag::before,
#ball .ball-drag::after {
    position: absolute;
    top: 50%;
    margin-top: -5px;
    font-size: 16px;
    color: var(--color-white);
    height: 15px;
    line-height: 15px;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased
}

#ball .ball-drag::before {
    content: "";
    left: 0;
    transform: translate3d(-30px, 0, 0);
    transition: .35s
}

#ball .ball-drag::after {
    content: "";
    right: 0;
    transform: translate3d(30px, 0, 0);
    transition: .35s
}

#ball.ball-close-enabled {
    opacity: 1 !important
}

#ball .ball-close {
    position: absolute;
    padding: 0 0px;
    font-size: 14px;
    font-weight: 600;
    color: var(--color-white);
    line-height: 1;
    text-align: center
}

.nav-style-one ul.nav {
    border-bottom: unset
}

.nav-style-one ul.nav li a.nav-link {
    border-radius: 0px;
    min-width: 130px;
    border: 1px solid var(--color-primary);
    padding: 12px 25px !important;
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    color: var(--text-primary);
    transition: .4s ease
}

.nav-style-one ul.nav li a.nav-link:hover {
    background-color: var(--color-primary);
    color: var(--color-white)
}

.nav-style-one ul.nav li a.nav-link.active {
    background-color: var(--color-primary);
    color: var(--color-white)
}

.nav-style-two ul.nav {
    border-bottom: unset;
    gap: 10px
}

.nav-style-two ul.nav li a.nav-link {
    min-width: 130px;
    border: 1px solid var(--color-primary);
    padding: 9px 18px !important;
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    border-radius: 30px;
    color: var(--text-primary);
    transition: .4s ease
}

.nav-style-two ul.nav li a.nav-link:hover {
    background-color: var(--color-primary);
    color: var(--color-white)
}

.nav-style-two ul.nav li a.nav-link.active {
    background-color: var(--color-primary);
    color: var(--color-white)
}

.nav-style-three.nav-sidebar {
    position: sticky;
    top: 100px
}

.nav-style-three.nav-sidebar ul.nav li .nav-link {
    text-align: left
}

.nav-style-three.nav-sidebar ul.nav li .nav-link i {
    margin-right: 12px;
    font-size: 18px
}

.nav-style-three ul.nav {
    border-bottom: unset;
    gap: 10px
}

.nav-style-three ul.nav li a.nav-link {
    min-width: 130px;
    border: 1px solid var(--bg-light);
    background-color: var(--bg-light);
    padding: 7px 16px !important;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    border-radius: 30px;
    color: var(--text-primary)
}

.nav-style-three ul.nav li a.nav-link:hover {
    background-color: var(--color-dark);
    color: var(--color-white);
    border: 1px solid var(--color-dark)
}

.nav-style-three ul.nav li a.nav-link i {
    margin-right: 5px;
    font-size: 14px
}

.nav-style-three ul.nav li a.nav-link.active {
    background-color: var(--color-dark);
    border: 1px solid var(--color-dark);
    color: var(--color-white)
}

.nav-style-four ul.nav {
    border-bottom: unset;
    gap: 10px
}

.nav-style-four ul.nav li a.nav-link {
    min-width: 130px;
    border: 1px solid var(--color-white);
    background-color: var(--color-white);
    padding: 8px 18px !important;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    border-radius: 30px;
    color: var(--text-primary)
}

.nav-style-four ul.nav li a.nav-link:hover {
    background-color: var(--text-primary);
    color: var(--color-white)
}

.nav-style-four ul.nav li a.nav-link i {
    margin-right: 5px;
    font-size: 14px
}

.nav-style-four ul.nav li a.nav-link.active {
    background-color: var(--text-primary);
    color: var(--color-white);
    position: relative
}

.nav-style-four ul.nav li a.nav-link.active::after {
    content: "";
    position: absolute;
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
    bottom: -5px;
    width: 10px;
    height: 10px;
    background-color: var(--color-dark)
}

.nav-style-five ul.nav {
    border-bottom: unset;
    gap: 6px
}

.nav-style-five ul.nav li a.nav-link {
    min-width: auto;
    border: 1px solid var(--bg-light);
    background-color: var(--bg-light);
    padding: 3px 16px !important;
    text-align: center;
    font-size: 15px;
    font-weight: 400;
    border-radius: 5px;
    color: var(--text-primary);
    word-break: normal
}

.nav-style-five ul.nav li a.nav-link:hover {
    background-color: var(--color-primary);
    color: var(--color-white);
    border-color: var(--color-primary);
}

.nav-style-five ul.nav li a.nav-link i {
    margin-right: 0px;
    font-size: 14px
}

.nav-style-five ul.nav li a.nav-link.active {
    background-color: var(--color-primary);
    color: var(--color-white);
    border-color: var(--color-primary);
}

.nav-style-six ul.nav {
    border-bottom: unset;
    gap: 5px
}

.nav-style-six ul.nav li a.nav-link {
    min-width: auto;
    border: unset;
    border-bottom: 2px solid rgba(0, 0, 0, 0);
    background-color: rgba(0, 0, 0, 0);
    padding: 2px 2px !important;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    border-radius: 0px;
    color: var(--text-secondary);
    word-break: normal;
    opacity: .75;
    margin-right: 25px
}

.nav-style-six ul.nav li a.nav-link i {
    margin-right: 5px;
    font-size: 12px
}

.nav-style-six ul.nav li a.nav-link:hover {
    border-bottom: 2px solid var(--color-primary)
}

.nav-style-six ul.nav li a.nav-link.active {
    border-bottom: 2px solid var(--color-primary);
    color: var(--text-primary);
    position: relative;
    opacity: 1
}

.swiper {
    padding: 12px;
    margin: -12px
}

.card-pagination {
    text-align: center;
    margin-top: 50px
}

.card-pagination .swiper-pagination-bullet {
    width: 16px;
    height: 16px;
    border-radius: 10px;
    background-color: var(--color-primary)
}

.card-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
    background-color: var(--color-primary)
}

.pagination {
    gap: 15px
}

@media(max-width: 767px) {
    .pagination {
        gap: 12px
    }
}

.pagination .page-item:first-child .page-link {
    border-radius: 30px
}

.pagination .page-item:last-child .page-link {
    border-radius: 30px
}

.pagination .page-link {
    padding: 12px 16px !important;
    line-height: 1;
    min-width: 42px;
    border-radius: 50%;
    text-align: center;
    color: var(--text-primary);
    font-size: 15px;
    transition: .4s ease;
    background-color: var(--bg-light);
    border: 1px solid var(--color-border) !important
}

@media(max-width: 767px) {
    .pagination .page-link {
        font-size: 13px;
        min-width: 25px;
        padding: 8px 12px !important
    }
}

.pagination .page-link:focus {
    box-shadow: unset
}

.pagination .page-link:hover {
    background-color: var(--color-primary);
    color: var(--color-white);
    border: 1px solid var(--color-primary) !important
}

.pagination .page-link.active {
    background-color: var(--color-primary);
    color: var(--color-white);
    border: 1px solid var(--color-primary) !important
}

.scroll-design::-webkit-scrollbar {
    width: 4px
}

.scroll-design::-webkit-scrollbar-thumb {
    background-color: #cdcdcd;
    border-radius: 6px
}

.scroll-design::-webkit-scrollbar-thumb:hover {
    background-color: #939393
}

.scroll-design::-webkit-scrollbar-track {
    background: #f1f1f1
}

.scroll-design::-webkit-scrollbar-thumb:active {
    background-color: #555
}

header.style-1 {
    position: absolute;
    top: 60px;
    left: 0;
    right: 0;
    background-color: transparent;
    width: 100%;
    z-index: 99;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 7%;
    padding-right: 7%;
}

header.style-1.sticky {
    position: sticky;
    top: 0px;
    left: 0;
    z-index: 999;
    background: rgba(6, 30, 58, .8);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    box-shadow: 5px 3px 40px rgba(100, 100, 100, .3)
}

header.style-1.sticky .topbar-area {
    display: none !important;
    visibility: hidden !important
}

@media(max-width: 1199px) {
    header.style-1 {
        padding: 10px 0px;
    }
}
@media(max-width: 991px) {
    header.style-1 {
        top: 40px;
    }
}

header.style-1 .header-left {
    display: flex;
    align-items: center;
}

header.style-1 .header-left .header-logo1 {
    position: relative;
    z-index: 1;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px
}

@media(max-width: 991px) {
    header.style-1 .header-left .header-logo1 {
        justify-content: flex-start
    }
}

header.style-1 .header-left .header-logo1 img {
    width: 100%;
    max-width: 130px
}

@media(min-width: 1200px)and (max-width: 1399px) {
    header.style-1 .header-left .header-logo1 img {
        max-width: 120px
    }
}

@media(min-width: 992px)and (max-width: 1199px) {
    header.style-1 .header-left .header-logo1 img {
        max-width: 110px
    }
}

@media(max-width: 991px) {
    header.style-1 .header-left .header-logo1 img {
        padding-left: 10px;
    }
}

header.style-1 .header-left .header-logo1::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    background-color: var(--primary-one-light);
    -webkit-clip-path: polygon(0 0, 100% 0%, 85% 100%, 0% 100%);
    clip-path: polygon(0 0, 100% 0%, 85% 100%, 0% 100%);
    z-index: -1;
    width: 110%;
    height: 100%
}

header.style-1 .nav-right {
    display: flex;
    align-items: center;
    gap: 25px
}

header.style-1 .sidebar-btn i {
    font-size: 28px;
    color: var(--color-white);
}

header.style-1 .mobile-logo-area .mobile-logo-wrap {
    max-width: 120px
}

header.style-1 .main-nav {
    display: inline-block;
    background-color: rgba(255,255,255,0.1);
    padding: 0px 25px;
    border-radius: 50px;
}

header.style-1 .main-nav .mobile-menu-logo {
    display: none
}

header.style-1 .main-nav ul {
    list-style: none;
    margin: 0;
    padding: 0
}

header.style-1 .main-nav ul>li {
    display: inline-block;
    position: relative;
    padding: 0 15px
}

header.style-1 .main-nav ul>li a {
    font-size: 17px;
    color: var(--color-white);
    font-weight: 300;
    display: block;
    text-transform: capitalize;
    padding: 15px 0;
    position: relative;
    transition: all .5s ease-out 0s
}

header.style-1 .main-nav ul>li a:hover::after {
    transform: scaleX(1)
}

header.style-1 .main-nav ul>li a::after {
    content: "";
    position: absolute;
    bottom: 0px;
    left: 0;
    display: block;
    width: 100%;
    background-color: var(--color-primary);
    height: 2px;
    transform: scaleX(0);
    transition: .5s;
    transform-origin: left
}

header.style-1 .main-nav ul>li a:hover {
    color: var(--color-primary)
}

header.style-1 .main-nav ul>li a.active {
    color: var(--color-primary);
}
header.style-1 .main-nav ul>li a.active::after {
    transform: scaleX(1)
}

header.style-1 .main-nav ul>li i {
    width: 20px;
    font-size: 12px;
    text-align: center;
    color: var(--text-primary);
    font-style: normal;
    position: absolute;
    right: -6px;
    top: 31px;
    z-index: 999;
    cursor: pointer;
    display: none
}

header.style-1 .main-nav ul>li ul.sub-menu {
    position: absolute;
    left: 0;
    right: 0;
    top: auto;
    margin: 0;
    padding: 0;
    opacity: 0;
    visibility: hidden;
    min-width: 215px;
    background: var(--color-white);
    text-align: left;
    transition: all .5s ease-out 0s;
    transform: translateY(20px);
    padding: 8px;
    z-index: 1;
    box-shadow: 0 3px 15px rgba(29, 38, 38, .09)
}

header.style-1 .main-nav ul>li ul.sub-menu.mega-menu {
    width: 100%;
    max-width: 1040px;
    min-width: 1040px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    left: -300px;
    padding: 20px 20px 35px 20px
}

@media(max-width: 1199px) {
    header.style-1 .main-nav ul>li ul.sub-menu.mega-menu {
        min-width: 0;
        padding: 0
    }
}

header.style-1 .main-nav ul>li ul.sub-menu.mega-menu>li {
    width: 25%
}

@media(max-width: 1199px) {
    header.style-1 .main-nav ul>li ul.sub-menu.mega-menu>li {
        width: 100%
    }
}

header.style-1 .main-nav ul>li ul.sub-menu.mega-menu .mega-submenu {
    display: flex;
    flex-direction: column;
    margin-top: 20px
}

@media(max-width: 1199px) {
    header.style-1 .main-nav ul>li ul.sub-menu.mega-menu .mega-submenu {
        margin-top: 0;
        padding: 0 0 15px 0
    }
}

header.style-1 .main-nav ul>li ul.sub-menu.mega-menu .mega-submenu li {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    margin-bottom: 30px
}

@media(max-width: 1199px) {
    header.style-1 .main-nav ul>li ul.sub-menu.mega-menu .mega-submenu li {
        margin-bottom: 15px
    }
}

header.style-1 .main-nav ul>li ul.sub-menu.mega-menu .mega-submenu li:last-child {
    margin-bottom: 0
}

header.style-1 .main-nav ul>li ul.sub-menu.mega-menu .mega-submenu li img {
    max-width: 35px
}

@media(max-width: 1199px) {
    header.style-1 .main-nav ul>li ul.sub-menu.mega-menu .mega-submenu li img {
        max-width: 22px
    }
}

header.style-1 .main-nav ul>li ul.sub-menu.mega-menu .mega-submenu li .content h6 {
    font-size: 14px;
    color: var(--text-primary)
}

header.style-1 .main-nav ul>li ul.sub-menu.mega-menu .mega-submenu li .content p {
    margin-bottom: 0;
    font-size: 14px;
    color: var(--text-secondary)
}

header.style-1 .main-nav ul>li ul.sub-menu>li {
    padding: 0 10px;
    padding: 0;
    display: block;
    position: relative
}

header.style-1 .main-nav ul>li ul.sub-menu>li i {
    position: absolute;
    top: 10px;
    right: 6px;
    display: block
}

header.style-1 .main-nav ul>li ul.sub-menu>li a {
    display: block;
    padding: 13px 15px;
    color: var(--text-primary);
    font-weight: 500;
    text-transform: capitalize;
    font-size: 16px;
    line-height: 1;
    transition: all .4s ease-out 0s
}

header.style-1 .main-nav ul>li ul.sub-menu>li a:hover {
    color: var(--color-primary)
}

header.style-1 .main-nav ul>li ul.sub-menu>li a.active {
    color: var(--color-primary)
}

header.style-1 .main-nav ul>li ul.sub-menu>li .sub-menu {
    left: 215px;
    position: absolute;
    max-width: 230px;
    min-width: 215px;
    background: var(--color-white);
    top: 0;
    box-shadow: 0 3px 15px rgba(29, 38, 38, .09)
}

@media only screen and (max-width: 1199px) {
    header.style-1 .main-nav ul>li ul.sub-menu>li .sub-menu {
        margin-left: 10px;
        position: unset;
        max-width: 230px;
        min-width: 215px;
        background: rgba(0, 0, 0, 0);
        top: 0
    }
}

header.style-1 .main-nav ul>li ul.sub-menu>li .sub-menu li i {
    display: block
}

header.style-1 .main-nav ul>li ul.sub-menu>li:last-child {
    border-bottom: none
}

header.style-1 .main-nav ul>li:hover>ul.sub-menu {
    visibility: visible;
    opacity: 1;
    transform: translateY(0)
}

header.style-1 .main-nav ul li.menu-item-has-children>i {
    display: block
}

@media only screen and (max-width: 1199px) {
    header.style-1 .main-nav {
        position: fixed;
        top: 0;
        left: 0;
        width: 280px;
        padding: 30px 20px !important;
        z-index: 99999;
        height: 100%;
        overflow: auto;
        background: var(--color-dark);
        border-right: 1px solid #555;
        transform: translateX(-100%);
        transition: transform .3s ease-in;
        border-radius: 0px;
    }

    header.style-1 .main-nav.show-menu {
        transform: translateX(0)
    }

    header.style-1 .main-nav .mobile-menu-logo {
        text-align: left;
        padding-top: 20px;
        display: block;
        padding-bottom: 8px
    }

    header.style-1 .main-nav ul {
        float: none;
        text-align: left;
        padding: 35px 10px 35px 0
    }

    header.style-1 .main-nav ul li {
        display: block;
        position: relative;
        padding: 0 5px
    }

    header.style-1 .main-nav ul li i {
        display: block
    }

    header.style-1 .main-nav ul li a {
        padding: 10px 0;
        display: block;
        font-weight: 500
    }

    header.style-1 .main-nav ul li ul.sub-menu {
        position: static;
        min-width: 200px;
        background: 0 0;
        border: none;
        opacity: 1;
        visibility: visible;
        box-shadow: none;
        transform: none;
        transition: none;
        display: none;
        margin-top: 0 !important;
        transform: translateY(0px);
        padding: 0
    }

    header.style-1 .main-nav ul li ul.sub-menu::after {
        content: none
    }

    header.style-1 .main-nav ul li ul.sub-menu.mege-manu {
        padding: 0 !important
    }

    header.style-1 .main-nav ul li .bi {
        top: 12px;
        font-size: 15px;
        font-weight: 500
    }

    header.style-1 .mobile-menu {
        position: relative;
        top: 2px;
        padding: 0 5px;
        border-radius: 50%;
        display: inline-block
    }

    header.style-1 .cross-btn {
        display: inline-block !important;
        position: relative;
        width: 30px !important;
        height: 22px !important;
        cursor: pointer;
        border: 3px solid rgba(0, 0, 0, 0) !important
    }

    header.style-1 .cross-btn span {
        width: 100%;
        height: 2px;
        background: var(--primary-color1);
        display: block;
        position: absolute;
        right: 0;
        transition: all .3s
    }

    header.style-1 .cross-btn .cross-top {
        top: 0
    }

    header.style-1 .cross-btn .cross-middle {
        top: 50%;
        transform: translateY(-50%);
        width: 100%
    }

    header.style-1 .cross-btn .cross-bottom {
        bottom: 0;
        width: 100%
    }

    header.style-1 .cross-btn.h-active span.cross-top {
        transform: rotate(45deg);
        top: 50%;
        margin-top: -1px
    }

    header.style-1 .cross-btn.h-active span.cross-middle {
        transform: translateX(-30px);
        opacity: 0
    }

    header.style-1 .cross-btn.h-active span.cross-bottom {
        transform: rotate(-45deg);
        bottom: 50%;
        margin-bottom: -1px
    }
    header.style-1 .main-nav ul>li a::after {
        content: unset;
    }
}

.header-item-btn {
    background-color: rgba(0, 0, 0, 0);
    font-size: 16px;
    color: var(--color-white);
    line-height: 1
}

.header-item-btn svg {
    fill: var(--color-white);
    width: 18px;
    height: 18px
}

.search-bar {
    position: fixed;
    inset: 0;
    width: 100%;
    height: 100vh;
    z-index: 1000;
    background: rgba(6, 30, 58, .9);
    transition: .5s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateY(-100%)
}

.search-bar .search-box {
    width: 100%;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    z-index: 2
}

.search-bar .search-box .search-history {
    opacity: 0;
    transform: translateY(50px);
    width: 100%;
    background-color: var(--color-dark);
    padding: 10px 30px;
    transition: .6s ease
}

.search-bar .search-box .search-history.showHistory {
    transform: translateY(0px);
    opacity: 1
}

.search-bar .search-box .search-history h6 {
    color: var(--color-white);
    margin-bottom: 15px
}

.search-bar .search-box .search-history ul li {
    margin-bottom: 12px
}

.search-bar .search-box .search-history ul li:last-child {
    margin-bottom: 0
}

.search-bar .search-box .search-history ul a {
    color: var(--text-light)
}

.search-bar .search-box .search-history ul i {
    margin-right: 10px
}

.search-bar form {
    width: 100% !important;
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--text-light)
}

.search-bar form input,
.search-bar form textarea {
    border: none;
    font-size: 16px;
    background-color: rgba(0, 0, 0, 0)
}

.search-bar form button {
    background-color: var(--color-primary-light);
    background-color: rgba(0, 0, 0, 0);
    height: 50px;
    width: 50px;
    line-height: 50px
}

@media(max-width: 991px) {
    .search-bar form {
        width: 90%
    }
}

.search-bar span {
    position: absolute;
    font-size: 36px;
    top: 6px;
    right: -40px;
    line-height: 1;
    color: var(--color-white);
    cursor: pointer
}

.search-bar .overlayClick {
    position: absolute;
    left: 0;
    top: 0px;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0);
    z-index: 1
}

.header-logo {
    max-width: 100px;
    padding: 5px 0
}

@media(max-width: 767px) {
    .header-logo {
        max-width: 80px
    }
}

.sidebar-button {
    display: flex;
    flex-direction: column;
    gap: 7px;
    cursor: pointer;
    align-items: flex-start;
    text-align: right;
    z-index: 9;
    position: relative
}

.sidebar-button span {
    display: inline-block;
    width: 30px;
    height: 2px;
    border-radius: 3px;
    background-color: var(--color-dark);
    transition: .5s ease
}

.sidebar-button span:nth-child(2) {
    width: 20px
}

.sidebar-button span:last-child {
    width: 25px
}

.sidebar-button:hover span:nth-child(2) {
    width: 30px
}

.sidebar-button:hover span:nth-child(3) {
    width: 30px
}

.menu-close-btn i {
    color: var(--color-white);
    font-size: 22px
}

.topbar {
    background-color: var(--color-dark);
    padding: 3px;
    border-bottom: 1px solid rgba(255,255,255,0.3);
}

.marquee {
    overflow: hidden;
    padding: 3px 0
}

.marquee .marquee-items {
    display: flex;
    align-items: center
}

.marquee .marquee-items .marquee-item {
    display: flex;
    align-items: center;
    justify-items: center;
    white-space: nowrap;
    padding: 2px 15px;
    gap: 10px;
    border-right: 1px solid var(--color-border)
}

.marquee .marquee-items .marquee-item .marquee-item-img {
    width: 20px
}

.marquee .marquee-items .marquee-item h6 {
    text-transform: uppercase;
    font-weight: 400;
    font-size: 14px;
    margin-bottom:0px ;
    color: var(--color-white)
}

.marquee .marquee-items .marquee-item p {
    font-size: 14px;
    margin-bottom: 0;
    color: var(--color-border)
}

.marquee .marquee-items .marquee-item span {
    font-size: 14px;
    color: var(--color-primary);
    font-weight: 400
}

.lan-select {
    display: flex;
    gap: 10px;
    cursor: pointer
}

.choose-item {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 25px
}

.choose-item:hover .icon::after {
    transform: scale(1.3) translateY(10px)
}

.choose-item .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 1;
    margin-top: 20px
}

.choose-item .icon::after {
    content: "";
    display: block;
    position: absolute;
    right: -20px;
    top: -20px;
    width: 60px;
    height: 60px;
    background-color: rgba(255, 255, 255, .1);
    border-radius: 50%;
    z-index: -1;
    transition: .5s ease
}

.choose-item .icon i {
    font-size: 45px;
    line-height: 1;
    color: var(--color-primary)
}

.choose-item .content h4 {
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 10px;
    position: relative;
    display: inline-block;
    color: var(--color-white)
}

@media (max-width:768px) {
    .choose-item .content h4 {
        font-size: 20px;
    }
}

.choose-item .content p {
    color: var(--color-white);
    opacity: .8
}

.blog-item-one {
    padding: 30px;
    background-color: var(--bg-light);
    margin-bottom: 25px
}

.blog-item-one .date h5 {
    font-size: 18px
}

.blog-item-one .date h5 span {
    display: inline-block;
    color: var(--text-secondary);
    font-size: 16px
}

.blog-item-one .blog-meta-list {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    gap: 30px;
    padding: 3px 20px;
    border-radius: 30px;
    border: 1px solid var(--color-border)
}

.blog-item-one .blog-meta-list li a {
    font-size: 14px;
    color: var(--text-secondary);
    transition: .4s ease
}

.blog-item-one .blog-meta-list li i {
    vertical-align: middle;
    margin-right: 10px
}

.blog-item-one .blog-meta-list li:hover a {
    color: var(--color-primary)
}

.blog-item-one .content {
    padding: 30px;
    background-color: var(--bg-light)
}

@media(max-width: 767px) {
    .blog-item-one .content {
        padding: 25px
    }
}

.blog-item-one .content h4 {
    margin-bottom: 25px
}

.blog-item-two {
    position: relative;
    box-shadow: 0px 3px 12px rgba(0,0,0,0.09);
}

.blog-item-two .image {
    position: relative
}

.blog-item-two .date {
    position: absolute;
    bottom: -22px;
    right: 0px;
    background-color: var(--color-dark);
    padding: 8px 20px;
    border: 1px solid var(--bg-light)
}

.blog-item-two .date h5 {
    margin-bottom: 2px;
    font-size: 22px;
    color: var(--color-white);
    line-height: 1
}

.blog-item-two .date p {
    margin-bottom: 0px;
    font-size: 13px;
    color: var(--color-white)
}

.blog-item-two .blog-meta-list {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    gap: 30px;
    margin-bottom: 15px
}

.blog-item-two .blog-meta-list li a {
    font-size: 14px;
    color: var(--text-secondary);
    transition: .4s ease
}

.blog-item-two .blog-meta-list li i {
    vertical-align: middle;
    margin-right: 10px
}

.blog-item-two .blog-meta-list li:hover a {
    color: var(--color-primary)
}

.blog-item-two .content {
    padding: 30px;
    background-color: var(--color-white);
}

@media (max-width: 768px){
    .blog-item-two .content {
        padding: 20px;
    }
}

.blog-item-two .content h4 {
    margin-bottom: 24px
}

.card--title {
    font-size: 24px;
    margin-bottom: 10px;
    color: var(--text-primary);
    line-height: 1.3;
}

@media(min-width: 992px)and (max-width: 1199px) {
    .card--title {
        font-size: 22px
    }
}

@media(min-width: 768px)and (max-width: 991px) {
    .card--title {
        font-size: 22px
    }
}

@media(max-width: 767px) {
    .card--title {
        font-size: 20px
    }
}

.card--title a {
    color: inherit
}

.testimonial-item {
    position: relative;
    border-radius: 10px;
    text-align: left
}

@media(max-width: 1199px) {
    .testimonial-item {
        text-align: center
    }
}

.testimonial-item .content {
    flex-grow: 1;
}

.testimonial-item .content p {
    font-size: 24px;
    font-weight: 400;
    margin-bottom: 30px;
    padding-bottom: 30px;
    color: var(--text-secondary);
    border-bottom: 1px solid var(--color-border);
}

@media(min-width: 992px)and (max-width: 1199px) {
    .testimonial-item .content p {
        font-size: 22px
    }
}

@media(min-width: 768px)and (max-width: 991px) {
    .testimonial-item .content p {
        font-size: 20px
    }
}

@media(max-width: 767px) {
    .testimonial-item .content p {
        font-size: 18px
    }
}

.testimonial-item .content .designation h6 {
    font-size: 18px;
    margin-bottom: 5px;
    color: var(--text-primary)
}

.testimonial-item .content .designation span {
    display: inline-block;
    font-size: 14px;
    color: var(--text-secondary);
    opacity: .8
}

.testimonial-item .image {
    min-width: 220px;
    max-width: 220px;
    height: 220px;
    margin-left: auto;
    margin-left: 40px;
    text-align: right;
    position: relative;
    z-index: 1;
    border-radius: 50%;
    overflow: hidden
}

.testimonial-item .image img {
    margin-left: auto;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover
}

.testimonial-card {
    background-color: var(--color-dark);
    position: relative;
    text-align: center;
    padding: 30px;
    border: 1px solid var(--color-border);
    border-radius: 50%;
    width: 280px;
    height: 280px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column
}

@media(min-width: 1200px)and (max-width: 1399px) {
    .testimonial-card {
        width: 260px;
        height: 260px
    }
}

@media(max-width: 1199px) {
    .testimonial-card {
        margin-left: auto;
        margin-right: auto;
        width: 240px;
        height: 240px
    }
}

.testimonial-card h5 {
    font-size: 30px;
    margin-bottom: 18px;
    color: var(--color-white)
}

.testimonial-card ul {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 7px;
    margin-bottom: 5px;
    background: var(--color-primary-light);
    padding: 3px 15px;
    border-radius: 30px;
}

.testimonial-card ul i {
    font-size: 16px;
    color: var(--color-primary)
}

.testimonial-card p {
    margin-bottom: 0;
    color: var(--text-light);
    font-size: 18px
}

.testimonial-card p span {
    display: inline-block;
    font-weight: 500;
    color: var(--color-white);
    margin-right: 5px
}

.process-item {
    position: relative;
    border: 1px solid var(--border-light);
    padding: 80px 40px 40px 40px;
    margin-top: 50px;
    background-color: var(--color-dark);
}

@media(max-width: 991px) {
    .process-item {
        padding: 35px 30px 35px 30px
    }
}

.process-item .serial {
    display: inline-block;
    font-size: 55px;
    color: var(--color-white);
    opacity: .09;
    font-weight: 500;
    position: absolute;
    top: 5px;
    left: 30px;
    line-height: 1
}

.process-item:hover .icon i {
    animation: zoom 1.5s linear infinite alternate
}

@keyframes zoom {
    0% {
        transform: scale(1)
    }

    50% {
        transform: scale(0.5)
    }

    100% {
        transform: scale(1)
    }
}

.process-item .icon {
    position: absolute;
    top: -50px;
    right: 30px;
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--color-primary);
    border-radius: 50%
}

@media(max-width: 991px) {
    .process-item .icon {
        top: -35px;
        width: 70px;
        height: 70px
    }
}

.process-item .icon i {
    font-size: 45px;
    color: var(--color-white)
}

@media(max-width: 991px) {
    .process-item .icon i {
        font-size: 35px
    }
}

.process-item .content h4 {
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 20px;
    color: var(--color-white);
    position: relative;
    z-index: 1
}

.process-item .content h4::before {
    content: "";
    position: absolute;
    left: -15px;
    top: -6px;
    width: 40px;
    height: 40px;
    background-color: var(--color-white);
    border-radius: 50%;
    z-index: -1;
    opacity: .15
}

.process-item .content p {
    margin-bottom: 0;
    color: var(--text-light)
}

.pricing-item {
    position: relative;
    z-index: 1;
    background-color: var(--color-dark);
    border: 1px solid var(--color-border);
    border-radius: 30px;
    transition: 0.5s ease;
}

.pricing-item:hover{
    border: 1px solid var(--color-primary);
}

.pricing-item.style-two{
    background: linear-gradient(-135deg, var(--color-primary-light-2),#fff);
    border: 1px solid var(--color-border);
}
.pricing-item.style-two .header{
    border-bottom: 1px solid var(--color-border);
}
.pricing-item.style-two .header .title{
    color: var(--color-primary);
    border-bottom: unset;
    transition: 0.4s ease;
}
.pricing-item.style-two:hover{
    border: 1px solid var(--color-primary);
}
.pricing-item.style-two:hover .header {
    background-color: inherit;
}


.pricing-item:hover .header .title{
    color: var(--border);
}

.pricing-item.style-two .price{
    color: var(--text-secondary);
}


.pricing-item .recommend {
    position: absolute;
    right: 0px;
    top: -1px;
    z-index: 2;
}

.pricing-item .recommend span {
    display: inline-block;
    text-transform: uppercase;
    font-size: 11px;
    letter-spacing: 1px;
    color: var(--color-white);
    background-color: var(--color-primary);
    font-weight: 400;
    opacity: .9;
    padding: 5px 15px 5px 22px;
    border-radius: 0px 50px 0 50px;
}

.pricing-item .icon {
    position: absolute;
    top: 30px;
    right: 25px;
    z-index: 1;
    opacity: 1;
    padding: 12px;
    border-radius: 50%
}

.pricing-item .icon img {
    width: 40px;
    height: 40px;
    fill: var(--text-light)
}

.pricing-item .header {
    padding: 40px 25px 25px;
    border-radius: 0px;
    border-bottom: 1px solid rgba(255,255,255,0.3);
}

@media(max-width: 991px) {
    .pricing-item .header {
        padding: 30px 20px
    }
}

.pricing-item .price {
    font-size: 32px;
    line-height: 1;
    font-weight: 500;
    color: var(--color-white);
    margin-bottom: 15px;
}

@media(max-width: 767px) {
    .pricing-item .price {
        font-size: 28px
    }
}

.pricing-item .title {
    font-size: 24px;
    color: var(--color-white);
    letter-spacing: 1px
}

@media(max-width: 767px) {
    .pricing-item .title {
        font-size: 22px
    }
}

.pricing-item p {
    color: var(--text-secondary)
}

.price-info{
    padding: 15px;
    border-radius: 12px;
    background-color: rgba(255,255,255,0.09);
    margin-top: 30px;
}
.price-info h6,.price-info p{
    color: var(--color-white);
    font-weight: 300;
    font-size: 14px;
}
.price-info p span{
    display: inline-block;
    color: var(--color-primary);
}
.pricing-item .pricing-list {
    margin-bottom: 20px;
}
.pricing-item .pricing-list li {
    font-size: 16px;
    font-weight: 300;
    margin-bottom: 18px;
    color: var(--color-white);
}


.pricing-item.style-two .pricing-list li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid #fff;
    color: var(--text-secondary);
}
.pricing-item.style-two .pricing-list li i{
    font-size: 16px;
}
.pricing-item.style-two .pricing-list li span{
    display: inline-block;
    flex-grow: 1;
}

@media(max-width: 767px) {
    .pricing-item .pricing-list li {
        font-size: 15px
    }
}

.pricing-item .pricing-list li:last-child {
    margin-bottom: 0px
}

.pricing-item .pricing-list li i {
    font-size: 20px;
    display: inline-block;
    margin-right: 10px;
    vertical-align: middle;
    color: var(--color-primary);
    transition: .4s ease;
    text-align: center;
    transition: .5s ease
}

.pricing-item .body {
    padding: 30px 25px;
}

@media(min-width: 992px)and (max-width: 1199px) {
    .pricing-item .body {
        padding: 25px 20px
    }
}

@media(max-width: 991px) {
    .pricing-item .body {
        padding: 25px 20px
    }
}

.pricing-item .body h6 {
    font-size: 17px;
    margin-bottom: 5px;
    margin-top: -5px;
}

@media(max-width: 767px) {
    .pricing-item .body h6 {
        font-size: 15px
    }
}

.pricing-item .body p {
    margin-bottom: 0px;
    font-size: 16px
}

.pricing-item .body p span {
    display: inline-block;
    color: var(--color-primary);
    font-weight: 500
}

.pricing-item .footer {
    padding: 0 30px 35px
}

.currency-wrapper.style-two {
    padding: 30px;
    background-color: var(--bg-light)
}

.our-currency-item .name {
    min-width: 90px
}

.our-currency-item .name .icon {
    width: 30px;
    height: 30px
}

.our-currency-item .name .content {
    transition: .6s ease
}

.our-currency-item .name .content h5 {
    font-size: 14px
}

.our-currency-item .amount {
    font-size: 14px
}

.our-currency-item .rate p {
    font-size: 13px
}

.currency-wrapper .our-currency-item {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 18px;
    flex-direction: row;
    overflow: hidden;
    z-index: 1;
    transition: .6s ease;
    border-bottom: 1px solid var(--color-border);
    padding: 15px 0
}

.currency-wrapper .our-currency-item:hover {
    background-color: var(--bg-light)
}

.currency-wrapper .our-currency-item:first-child {
    padding-top: 0px
}

.currency-wrapper .our-currency-item .name {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    min-width: 100px
}

.currency-wrapper .our-currency-item .name .icon {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    overflow: hidden
}

@media (max-width: 768px) {
    .our-currency-item .avatar--md{
        margin-left: auto;
    }
}

.our-currency-item .name .content {
    transition: .6s ease
}

.our-currency-item .name .content h5 {
    font-size: 15px;
    line-height: 1
}
.our-currency-item .name .content span {
    color: var(--text-secondary);
    display: inline-block;
    font-size: 13px;
}

.currency-wrapper .our-currency-item .name .content>span {
    display: inline-block;
    font-size: 14px;
    line-height: 1
}

.currency-wrapper .our-currency-item .name .content p {
    margin-bottom: 0;
    font-weight: 400;
    color: var(--color-primary);
    font-size: 18px
}

.currency-wrapper .our-currency-item .amount {
    font-size: 15px;
    font-weight: 400
}

.currency-wrapper .our-currency-item .rate p {
    margin-bottom: 0;
    font-weight: 500;
    font-size: 15px;
}

.currency-wrapper .our-currency-item:last-child {
    margin-bottom: 0;
    border: unset;
    padding-bottom: 0;
}

.crypto-news {
    padding: 45px 45px;
    background-color: var(--bg-light)
}

@media(max-width: 767px) {
    .crypto-news {
        padding: 25px
    }
}

@media(min-width: 768px)and (max-width: 991px) {
    .crypto-news {
        padding: 30px
    }
}

@media(min-width: 992px)and (max-width: 1199px) {
    .crypto-news {
        padding: 35px
    }
}

.news-item {
    margin-bottom: 25px
}

.news-item:last-child {
    margin-bottom: 0
}

.news-item h6 {
    margin-bottom: 3px;
    line-height: 1.4;
    font-size: 15px;
    font-weight: 400;
}

.news-item h6 a {
    font-size: 17px;
    text-decoration: none;
    color: inherit;
    transition: .4s ease
}

.news-item h6 a:hover {
    color: var(--color-primary)
}

.news-item span {
    font-size: 14px
}

.news-item span i {
    color: var(--text-primary);
    margin-right: 8px;
    margin-left: 1px;
    vertical-align: middle
}

.converstion-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 35px 30px;
    background-color: var(--color-white);
    border: 1px solid var(--color-border);
    gap: 30px;
    border-radius: 20px;
}


@media (min-width: 768px) and (max-width: 1199px) {
    .converstion-item{
        flex-direction: column;
    }
    .converstion-item .content {
        text-align: center !important;
    }
}

@media(max-width: 768px) {
    .converstion-item {
        padding: 25px 25px;
        text-align: center;
        justify-content: center;
        gap: 25px
    }
}

.converstion-item:hover .icons img:first-child {
    margin-right: -50px
}

.converstion-item .content h5 {
    margin-bottom: 10px;
    line-height: 1;
    font-size: 18px
}

.converstion-item .content h5 i {
    margin: 0 6px
}
.converstion-item .content {
    flex-grow: 1;
    text-align: left;
}
.converstion-item .icons {
    flex-shrink: 0;
}
.converstion-item .content p {
    margin-bottom: 0
}
.converstion-item .icons {
    display: flex;
    justify-content: center
}

.converstion-item .icons img {
    min-width: 45px;
    height: 45px;
    border-radius: 50%;
    transition: .4s ease
}

.converstion-item .icons img:first-child {
    margin-right: -10px
}

a {
    color: var(--text-secondary)
}

.form-title {
    font-size: 26px;
    margin-bottom: 30px;
    font-weight: 500;
    position: relative;
    z-index: 1
}

@media(max-width: 767px) {
    .form-title {
        font-size: 22px
    }
}

.form-title::before {
    content: "";
    position: absolute;
    left: -15px;
    top: -3px;
    width: 35px;
    height: 35px;
    background-color: var(--color-primary-light);
    border-radius: 50%;
    z-index: -1
}

.form-inner {
    margin-bottom: 25px;
    z-index: 1;
    display: flex;
    flex-direction: column
}

.form-wrapper {
    padding: 40px 40px;
    background-color: var(--color-white)
}

.form-wrapper.login-form {
    width: 100%;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto
}

.form-wrapper.contact-form {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, .05);
    padding: 50px 50px
}

@media(min-width: 992px)and (max-width: 1199px) {
    .form-wrapper.contact-form {
        padding: 40px 40px
    }
}

@media(max-width: 991px) {
    .form-wrapper.contact-form {
        padding: 30px 30px
    }
}

.form-wrapper.contact-form input,
.form-wrapper.contact-form textarea {
    border: 1px solid var(--color-border);
}

.form-wrapper.contact-form input:focus,
.form-wrapper.contact-form textarea:focus {
    border: 1px solid var(--color-primary)
}

.form-wrapper.style-two {
    padding: 0
}

.form-wrapper.style-two input,
.form-wrapper.style-two textarea {
    border: 1px solid var(--color-border)
}

.form-wrapper.style-two input:focus,
.form-wrapper.style-two textarea:focus {
    border: 1px solid var(--color-primary)
}

@media(min-width: 1200px)and (max-width: 1399px) {
    .form-wrapper {
        padding: 35px 30px
    }
}

@media(min-width: 992px)and (max-width: 1199px) {
    .form-wrapper {
        padding: 35px 30px
    }
}

@media(max-width: 991px) {
    .form-wrapper {
        padding: 30px 30px
    }
}

.form-wrapper2 {
    padding: 0px 15%
}

.form-wrapper2 label {
    font-size: 15px
}

.form-wrapper2 .another-singup .or {
    background-color: rgba(0, 0, 0, 0) !important
}

.form-inner {
    position: relative
}

.form-inner input[type=date],
.form-inner textarea[type=date] {
    z-index: 1;
    background-color: rgba(0, 0, 0, 0)
}

.form-inner input[type=date]::after,
.form-inner textarea[type=date]::after {
    content: "";
    position: absolute;
    right: 10px;
    top: 9px;
    font-family: "Bootstrap-icons";
    z-index: 9
}

label {
    font-size: 15px;
    color: var(--text-primary);
    margin-bottom: 5px;
    font-weight: 400
}

input,
textarea {
    background-color: rgba(0, 0, 0, 0);
    outline: none;
    border: 1px solid var(--color-border);
    width: 100%;
    display: block;
    padding: 10px 15px;
    border-radius: 10px;
    font-size: 16px;
    color: var(--text-secondary);
    transition: .4s ease;
    transition: .5s ease
}

@media(max-width: 991px) {

    input,
    textarea {
        padding: 8px 15px
    }
}

input:focus,
textarea:focus {
    border: 1px solid var(--color-primary)
}

input::-moz-placeholder,
textarea::-moz-placeholder {
    font-size: 13px;
    color: #999;
    font-weight: 300
}

input::placeholder,
textarea::placeholder {
    font-size: 13px;
    color: #999;
    font-weight: 300
}

textarea {
    min-height: 100px
}

select {
    background-color: rgba(0, 0, 0, 0);
    outline: none;
    border: 1px solid var(--color-border);
    width: 100%;
    display: block;
    padding: 8px 15px;
    border-radius: 10px;
    font-size: 15px
}

.select--sm {
    padding: 4px 15px
}

input[type=color],
textarea[type=color] {
    background-color: rgba(0, 0, 0, 0);
    outline: none;
    height: 40px;
    padding: 5px
}

input[type=file],
textarea[type=file] {
    background-color: rgba(0, 0, 0, 0);
    outline: none;
    padding: 10px
}

input[type=checkbox],
textarea[type=checkbox] {
    min-width: 15px !important;
    height: 15px;
    padding: 0;
    display: inline-block;
    cursor: pointer
}

input[type=checkbox]:focus,
textarea[type=checkbox]:focus {
    box-shadow: none
}

.form-check-input:checked {
    background-color: var(--color-primary);
    border-color: var(--color-primary);
    border: 1px solid var(--color-primary)
}

input[type=radio],
textarea[type=radio] {
    min-width: 15px !important;
    height: 15px;
    padding: 0;
    display: inline-block
}

input[type=radio]:focus,
textarea[type=radio]:focus {
    box-shadow: none
}

select {
    width: 100% !important;
    display: block
}

.select2.select2-container {
    width: 100% !important
}

.select2-container .select2-selection--single {
    box-sizing: border-box;
    cursor: pointer;
    display: block;
    width: 100% !important;
    height: 44px;
    line-height: 44px;
    -moz-user-select: none;
    user-select: none;
    -webkit-user-select: none
}

.select2-container--default .select2-selection--single {
    border-radius: 0px;
    border: 1px solid var(--color-border)
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
    color: var(--text-secondary);
    line-height: 40px;
    font-size: 14px;
    padding-left: 15px
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 40px
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 26px;
    position: absolute;
    top: 8px;
    right: 6px;
    width: 20px
}

.select2-container--default .select2-results__option--selected {
    color: var(--color-white)
}

.flatpickr-day.startRange {
    background-color: var(--color-primary);
    border-color: var(--color-primary)
}

.flatpickr-day.endRange {
    background-color: var(--color-primary);
    border-color: var(--color-primary)
}

.flatpickr-day.selected {
    background-color: var(--color-primary);
    border-color: var(--color-primary)
}

.flatpickr-day.selected:hover {
    border-color: var(--text-secondary);
    background-color: var(--text-secondary)
}

.sign-other-title {
    margin-top: 20px;
    text-align: center;
    position: relative;
    z-index: 1
}

.sign-other-title::before {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    width: 100%;
    height: 1px;
    background-color: var(--color-border);
    z-index: -1
}

.sign-other-title h6 {
    color: var(--text-secondary);
    background-color: var(--color-white);
    display: inline-block;
    padding: 15px
}

.signup-buttons-group {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px
}

.signup-buttons-group button {
    box-shadow: none;
    background-color: unset;
    transition: .3s;
    display: inline-block
}

.signup-buttons-group button:hover {
    transform: translateY(3px)
}

.signup-buttons-group button i.facebook {
    color: #4564ff
}

.signup-buttons-group button i.google {
    color: #cb0000
}

.signup-buttons-group button i.twitter {
    color: #02b0e0
}

.have-account {
    text-align: center;
    margin-top: 25px
}

.have-account p {
    color: var(--text-secondary);
    font-size: 16px
}

@media(max-width: 767px) {
    .have-account p {
        font-size: 14px
    }
}

.have-account a {
    color: var(--text-primary);
    font-size: 16px;
    font-weight: 600;
    transition: .4s ease;
    margin-left: 5px
}

.have-account a:hover {
    color: var(--color-primary)
}

.forgot-pass {
    color: var(--text-secondary) !important;
    font-size: 14px;
    font-weight: 600;
    text-decoration: underline !important;
    transition: .3s ease
}

.forgot-pass:hover {
    color: var(--color-primary) !important
}

.unlock-account {
    text-align: center;
    margin-bottom: 30px
}

.unlock-account .image {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    overflow: hidden;
    margin: 0 auto 10px
}

.unlock-account h5 {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 30px
}

.uploadFile {
    width: 100%;
    background-color: #fff;
    font-size: 16px;
    overflow: hidden;
    padding: 10px 10px 10px 10px;
    position: relative;
    resize: none;
    height: 90px
}

.uploadFile [type=file] {
    cursor: pointer !important;
    display: block;
    min-height: 100%;
    min-width: 100%;
    opacity: 0;
    position: absolute;
    right: 0px;
    text-align: right;
    top: 0px;
    z-index: 1
}

.uploadFile span {
    display: inline-block;
    font-size: 14px;
    color: var(--text-light);
    font-weight: 400
}

.form-group input,
.form-group textarea {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer
}

.form-group label {
    position: relative;
    cursor: pointer;
    margin-bottom: 0;
    border-radius: 4px
}

.form-group label:before {
    content: "";
    -webkit-appearance: none;
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid var(--color-border);
    padding: 10px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 5px;
    top: -1px
}

.form-group input:checked+label:after,
.form-group textarea:checked+label:after {
    content: "";
    display: block;
    position: absolute;
    top: 5px;
    left: 8px;
    width: 6px;
    height: 12px;
    border: 1px solid var(--color-primary);
    border-width: 0 2px 2px 0;
    transform: rotate(45deg)
}

.form-section {
    min-height: 100vh;
    padding: 90px 0px;
    position: relative;
    z-index: 1;
    background-color: var(--color-dark);
    overflow: hidden;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    flex-direction: column
}

@media(max-width: 991px) {
    .form-section {
        height: auto
    }
}

.form-section.white {
    background-color: var(--color-white)
}

.form-section .form-bg {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    max-width: 50%;
    z-index: -1
}

@media(max-width: 991px) {
    .form-section .form-bg {
        max-width: 100%
    }
}

.form-section .form-bg::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.7))
}

.form-section .form-bg img {
    min-height: 100%;
    max-height: 100%;
    -o-object-fit: cover;
    object-fit: cover
}

.form-section .form-bg2 {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    max-width: 50%;
    z-index: -1
}

@media(max-width: 991px) {
    .form-section .form-bg2 {
        max-width: 100%
    }
}

.form-section .form-bg2::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.6))
}

.form-section .form-bg2 img {
    min-height: 100%;
    max-height: 100%;
    -o-object-fit: cover;
    object-fit: cover
}

.form-section .form-wrap {
    height: 100vh
}

@media(max-width: 991px) {
    .form-section .form-wrap {
        height: auto
    }
}

.form-section .eth-icon {
    position: absolute;
    bottom: 90px;
    right: 50px;
    width: 60px;
    opacity: .05;
    z-index: -1
}

.form-section .ada-icon {
    position: absolute;
    top: 0;
    right: 50px;
    width: 60px;
    opacity: .05;
    z-index: -1
}

.form-section .sol-icon {
    position: absolute;
    top: -80px;
    left: 50px;
    width: 60px;
    opacity: .05;
    z-index: -1
}

.form-section .bnb-icon {
    position: absolute;
    bottom: -90px;
    left: 50px;
    width: 60px;
    opacity: .05;
    z-index: -1
}

.another-singup {
    text-align: center
}

.another-singup .or {
    padding: 30px 10px;
    position: relative;
    display: block;
    z-index: 4
}

.another-singup .or::before {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    height: 1px;
    width: 100%;
    background: linear-gradient(90deg, #dadce0, transparent, #dadce0);
    z-index: -2;
    display: block
}

.another-singup h6 {
    margin-bottom: 20px
}

.form-social {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 20px
}

.form-social>a i {
    font-size: 20px;
    vertical-align: middle;
    border-radius: 50%;
    line-height: 1;
    min-width: 45px;
    line-height: 46px;
    display: inline-block;
    transition: .4s ease
}

.form-social>a i.google {
    color: #e94234;
    background-color: rgba(233, 66, 52, .1)
}

.form-social>a i.google:hover {
    background-color: #e94234;
    color: var(--color-white)
}

.form-social>a i.facebook {
    color: #1773ea;
    background-color: rgba(23, 115, 234, .1)
}

.form-social>a i.facebook:hover {
    background-color: #1773ea;
    color: var(--color-white)
}

.form-social>a i.linkedin {
    color: #0173af;
    background-color: rgba(1, 115, 175, .1)
}

.form-social>a i.linkedin:hover {
    background-color: #0173af;
    color: var(--color-white)
}

.form-social-two {
    display: flex;
    justify-content: space-between;
    gap: 15px
}

.form-social-two a {
    width: 100%;
    transition: .4s ease;
    border-radius: 5px
}

.form-social-two a.google {
    background-color: #e94234;
    color: var(--color-white)
}

.form-social-two a.google:hover {
    color: #e94234;
    background-color: rgba(233, 66, 52, .1)
}

.form-social-two a.facebook {
    background-color: #1773ea;
    color: var(--color-white)
}

.form-social-two a.facebook:hover {
    color: #1773ea;
    background-color: rgba(23, 115, 234, .1)
}

.form-social-two a.linkedin {
    background-color: #0173af;
    color: var(--color-white)
}

.form-social-two a.linkedin:hover {
    color: #0173af;
    background-color: rgba(1, 115, 175, .1)
}

.form-social-two a i {
    font-size: 20px;
    vertical-align: middle;
    border-radius: 50%;
    line-height: 1;
    min-width: 45px;
    line-height: 46px;
    display: inline-block
}

.form-left {
    width: 100%;
    max-width: 650px;
    margin: 0 auto;
    padding: 0px 10px;
    text-align: center
}

.form-left .logo {
    max-width: 200px;
    margin: 0 auto 40px;
    display: inline-block
}

@media(max-width: 767px) {
    .form-left .logo {
        max-width: 150px
    }
}

.form-left h1 {
    font-size: 55px;
    color: var(--color-white);
    margin-bottom: 20px;
    line-height: 1.1
}

@media(min-width: 1200px)and (max-width: 1399px) {
    .form-left h1 {
        font-size: 45px
    }
}

@media(min-width: 992px)and (max-width: 1199px) {
    .form-left h1 {
        font-size: 45px
    }
}

@media(max-width: 991px) {
    .form-left h1 {
        font-size: 35px
    }
}

.form-left p {
    color: var(--color-white);
    opacity: .8
}

.form-left h6 {
    color: var(--color-white);
    font-size: 18px
}

.form-left #countdown {
    margin-top: 40px
}

.form-left .count-down-list {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    gap: 15px
}

.form-left .count-down-list li {
    padding: 8px;
    border: 1px solid rgba(255, 255, 255, .4);
    min-width: 85px;
    text-align: center;
    color: var(--color-white);
    font-size: 14px
}

.form-left .count-down-list span {
    display: block;
    font-size: 22px;
    color: var(--color-white);
    font-weight: 500
}

.address-wrapper {
    padding: 50px 50px;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column
}

@media(min-width: 992px)and (max-width: 1199px) {
    .address-wrapper {
        padding: 40px 40px
    }
}

@media(max-width: 991px) {
    .address-wrapper {
        padding: 30px 30px
    }
}

.address-wrapper .address-item {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 25px;
    padding-bottom: 25px;
    gap: 0px;
    border-bottom: 1px solid rgba(255, 255, 255, .2)
}

@media(max-width: 767px) {
    .address-wrapper .address-item {
        gap: 10px;
    }
}

.address-wrapper .address-item:last-child {
    margin-bottom: 0;
    border-bottom: none
}

.address-wrapper .address-item .icon {
    min-width: 60px;
    height: 60px;
    line-height: 60px;
    border-radius: 50%;
    text-align: left
}

@media(max-width: 767px) {
    .address-wrapper .address-item .icon {
        min-width: 40px;
        height: 40px;
        line-height: 40px
    }
}

.address-wrapper .address-item .icon i {
    color: var(--color-primary);
    font-size: 32px
}

@media(max-width: 767px) {
    .address-wrapper .address-item .icon i {
        font-size: 24px;
    }
}

.address-wrapper .address-item .content h5 {
    color: var(--color-white);
    margin-bottom: 8px;
    line-height: 1;
    opacity: .7;
    font-weight: 300;
    font-size: 18px
}

@media(max-width: 767px) {
    .address-wrapper .address-item .content h5 {
        font-size: 16px
    }
}

.address-wrapper .address-item .content p {
    color: var(--color-white);
    margin-bottom: 0;
    font-size: 22px
}

@media (max-width:768px) {
    .address-wrapper .address-item .content p {
        font-size: 16px
    }
}

.address-wrapper .address-item .content a {
    color: var(--color-white);
    font-size: 20px;
}

.table-container {
    overflow-x: auto;
    width: 100%;
    position: relative
}

table {
    border-collapse: collapse;
    margin: 0;
    padding: 0;
    max-width: 100%;
    width: 100%
}
table p{
    margin-bottom: 0;
}

table tr {
    border-bottom: 1px solid rgba(238, 238, 238, .15);
}

table tr:nth-child(even) td {
    background-color: rgba(0, 0, 0, .05)
}

table th {
    padding: 10px 15px !important;
    text-align: left;
    white-space: nowrap;
    font-size: 15px !important;
    font-weight: 400;
    color: var(--color-primary) !important;
    background-color: var(--color-primary-light-2) !important
}

table td {
    padding: 15px 15px !important;
    font-weight: 400;
    font-size: 15px;
    text-align: left;
    white-space: nowrap;
    vertical-align: middle;
}

@media(max-width: 767px) {
    table td {
        padding: 15px 20px;
    }
}

table th:first-child,
table td:first-child {
    text-align: left
}

table th:last-child,
table td:last-child {
    text-align: right
}

.table-action {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 1rem
}

@media screen and (max-width: 767px) {
    table {
        border: 0
    }

    table thead {
        border: none;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px
    }

    table tr {
        border: 1px solid var(--border);
        border-bottom: 2px solid #ddd;
        display: block;
        margin-bottom: 1.5rem
    }

    table tr:last-child {
        margin-bottom: 0px !important
    }

    table td {
        border-bottom: 1px solid rgba(255,255,255,0.2);
        display: block;
        font-size: 14px;
        font-weight: 400;
        text-align: right
    }
}

@media screen and (max-width: 767px)and (max-width: 767px) {
    table td .form-switch {
        padding-left: 100%
    }
}

@media screen and (max-width: 767px) {
    table td::before {
        content: attr(data-label);
        float: left;
        font-weight: 500;
        text-transform: uppercase;
        color: var(--text-primary)
    }

    table td:last-child {
        border-bottom: 0
    }

    table th:first-child,
    table td:first-child {
        text-align: right
    }

    table th:last-child,
    table td:last-child {
        text-align: right
    }
}

.trade-table {
    background-color: var(--color-white);
    height: auto;
    max-height: 490px;
    overflow: auto
}

@media(max-width: 767px) {
    .trade-table {
        max-height: 100%;
        overflow: unset
    }
}

.trade-table table thead tr {
    border-top: unset !important
}

.trade-table table thead tr th {
    padding: 10px 12px !important;
    font-weight: 400;
    font-size: 15px !important;
    text-align: left;
    white-space: nowrap;
    vertical-align: middle;
    color: var(--color-white) !important;
    background-color: var(--color-dark-2) !important;
    opacity: 0.9;
}

.trade-table table tr:nth-child(even) td {
    background-color: rgba(0, 0, 0, 0)
}

.trade-table table tr td {
    padding: 10px 12px !important;
    font-weight: 300;
    font-size: 14px;
    text-align: left;
    white-space: nowrap;
    vertical-align: middle;
    color: rgba(255,255,255,0.8) !important;
    background-color: var(--color-dark-2) !important;
}

@media(max-width: 767px) {
    .trade-table table tr td {
        text-align: right
    }
}

.d-sidebar {
    background: var(--color-white);
    position: fixed;
    width: 280px;
    height: 100%;
    left: 0;
    right: unset;
    top: 0;
    transition: .75s ease-in-out;
    z-index: 110
}

@media(max-width: 1199px) {
    .d-sidebar {
        left: -100%
    }
}

.d-sidebar.show-user-sidebar {
    left: 0
}

.d-sidebar .sidebar-logo {
    height: 70px;
    line-height: 70px;
    display: flex;
    align-items: lef;
    justify-content: flex-start;
    padding: 15px;
    border-bottom: 1px solid var(--color-primary-light-2);
    padding: 11px 20px;
    max-width: 190px;
    margin-left: 0;
    margin-right: auto
}

.d-sidebar .sidebar-menu-container {
    max-height: calc(100vh - 70px);
    height: 100%;
    padding: 15px 25px
}

@media(max-width: 767px) {
    .d-sidebar .sidebar-menu-container {
        padding: 15px 20px
    }
}

.d-sidebar .sidebar-menu-container .sidebar-menu {
    display: flex;
    flex-direction: column;
    gap: 1px
}

.sidebar-menu-title {
    color: var(--text-light);
    padding: 15px 15px 6px;
    font-size: 10px;
    text-transform: uppercase
}

.sidebar-menu-item {
    margin-bottom: 15px
}

.sidebar-menu-item .sidebar-menu-link {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 8px;
    padding: 10px 10px 10px 10px;
    font-size: 17px;
    line-height: 1;
    transition: .4s ease-in-out;
    font-weight: 400;
    border-radius: 50px;
    border-left: 2px solid rgba(0, 0, 0, 0);
    border-bottom: 2px solid rgba(0, 0, 0, 0)
}

.sidebar-menu-item .sidebar-menu-link.active {
    background-color: var(--color-primary-light-2);
    border-left: 2px solid var(--color-primary);
    border-bottom: 2px solid var(--color-primary)
}

.sidebar-menu-item .sidebar-menu-link.active p {
    color: var(--color-primary)
}

.sidebar-menu-item .sidebar-menu-link.active span i {
    color: var(--color-primary)
}

.sidebar-menu-item .sidebar-menu-link:hover {
    background-color: var(--color-primary-light-2);
    border-left: 2px solid var(--color-primary);
    border-bottom: 2px solid var(--color-primary)
}

.sidebar-menu-item .sidebar-menu-link:hover p {
    color: var(--color-primary)
}

.sidebar-menu-item .sidebar-menu-link:hover span i {
    color: var(--color-primary)
}

.sidebar-menu-item .sidebar-menu-link>span {
    width: 24px;
    height: 24px;
    line-height: 1;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--text-primary)
}

.sidebar-menu-item .sidebar-menu-link>span i {
    color: var(--text-primary);
    vertical-align: middle;
    transition: .4s ease-in-out;
    font-size: 18px
}

.sidebar-menu-item .sidebar-menu-link>p {
    color: var(--text-primary);
    display: flex;
    align-items: center;
    gap: 5px;
    margin-bottom: 0 !important;
    transition: .4s ease-in-out
}

.sidebar-menu-item .sidebar-menu-link>small {
    font-size: 13px;
    margin-left: auto
}

.sidebar-menu-item .side-menu-dropdown {
    margin-left: 20px;
    margin-top: 5px
}

.sidebar-menu-item .side-menu-dropdown .sub-menu {
    display: grid;
    gap: 5px
}

.sidebar-menu-item .side-menu-dropdown .sub-menu .sub-menu-item .sidebar-menu-link {
    padding: 10px 20px 10px 15px
}

.sidebar-menu-item .side-menu-dropdown .sub-menu .sub-menu-item .sidebar-menu-link span {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    border: 1px solid #fff
}

.sidebar-menu-item .side-menu-dropdown .sub-menu .sub-menu-item .sub-menu-dropdown {
    margin-left: 15px
}

.d-sidebar .sidebar-menu-container .simplebar-scrollbar:before {
    background: rgba(213, 213, 213, .6117647059)
}

@media(max-width: 991px) {
    .d-sidebar {
        z-index: 102
    }
}

.overlay-bg {
    background-color: rgba(21, 33, 48, .5);
    position: fixed;
    left: -100%;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 101;
    display: block;
    transition: .4s ease-in-out;
    opacity: 0;
    cursor: crosshair
}

.d-header {
    width: calc(100% - 280px);
    margin-left: auto;
    height: 70px;
    padding: 15px;
    background-color: var(--color-white);
    position: sticky;
    top: 0;
    z-index: 100;
    transition: .4s;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0 3px 15px rgba(29, 38, 38, .07)
}

@media(max-width: 1199px) {
    .d-header {
        width: 100%;
        margin-left: 0
    }
}

@media(min-width: 768px)and (max-width: 991px) {
    .d-header {
        transition: unset
    }
}

.d-header .d-header-right {
    display: flex;
    justify-content: flex-end;
    gap: 30px;
    align-items: center
}

@media(max-width: 991px) {
    .d-header .d-header-right {
        gap: 20px
    }
}

.d-header .d-header-right .dropdown-toggle::after {
    border: unset
}

.d-header .balance-dropdown {
    padding: 20px
}

.d-header .balance-dropdown img {
    max-width: 120px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
    opacity: .25
}

.d-header .notification-dropdown {
    position: relative;
    cursor: pointer
}

.d-header .notification-dropdown>span {
    position: absolute;
    top: -1px;
    right: -1px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 1px solid var(--color-white);
    background-color: var(--color-primary);
    color: var(--color-primary-text);
    font-size: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1
}

.d-header .notification-dropdown .dropdown-menu {
    width: 320px;
    line-height: 1;
    padding: 0;
    overflow: hidden;
    border-radius: 0
}

.d-header .notification-dropdown .dropdown-menu .dropdown-menu-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    padding: 10px 15px;
    border-bottom: 1px solid var(--color-border);
    background-color: var(--color-warning-light)
}

.d-header .notification-dropdown .dropdown-menu .dropdown-menu-title h6 {
    font-size: 18px
}

.d-header .notification-dropdown .dropdown-menu .dropdown-menu-title button {
    font-size: 12px
}

.d-header .notification-dropdown .dropdown-menu .notification-items {
    padding: 15px 0 0;
    max-height: 350px;
    overflow-y: auto
}

.d-header .notification-dropdown .dropdown-menu .notification-items::-webkit-scrollbar {
    width: 5px
}

.d-header .notification-dropdown .dropdown-menu .notification-items::-webkit-scrollbar-track {
    background: #f1f1f1
}

.d-header .notification-dropdown .dropdown-menu .notification-items::-webkit-scrollbar-thumb {
    background: #888
}

.d-header .notification-dropdown .dropdown-menu .notification-items::-webkit-scrollbar-thumb:hover {
    background: #555
}

.d-header .notification-dropdown .dropdown-menu .notification-items .notification-item {
    margin-bottom: 10px
}

.d-header .notification-dropdown .dropdown-menu .notification-items .notification-item>span {
    font-size: 12px;
    color: var(--text-secondary);
    padding-left: 15px;
    display: block
}

.d-header .notification-dropdown .dropdown-menu .notification-items .notification-item ul {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
    margin-top: 5px
}

.d-header .notification-dropdown .dropdown-menu .notification-items .notification-item ul li a {
    display: flex;
    align-items: flex-start;
    gap: 10px;
    padding: 10px 15px;
    transition: .3s ease;
    position: relative
}

.d-header .notification-dropdown .dropdown-menu .notification-items .notification-item ul li a:hover {
    background-color: var(--color-primary-light-2)
}

.d-header .notification-dropdown .dropdown-menu .notification-items .notification-item ul li a .notify-icon {
    width: 12%
}

.d-header .notification-dropdown .dropdown-menu .notification-items .notification-item ul li a .notify-icon img {
    width: 100%;
    height: 100%;
    border-radius: 50%
}

.d-header .notification-dropdown .dropdown-menu .notification-items .notification-item ul li a .notification-item-content {
    width: 88%
}

.d-header .notification-dropdown .dropdown-menu .notification-items .notification-item ul li a .notification-item-content h5 {
    font-size: 14px;
    display: flex;
    align-items: flex-end;
    gap: 10px
}

.d-header .notification-dropdown .dropdown-menu .notification-items .notification-item ul li a .notification-item-content h5 small {
    font-size: 10px;
    color: var(--text-secondary)
}

.d-header .notification-dropdown .dropdown-menu .notification-items .notification-item ul li a .notification-item-content p {
    margin-top: 5px;
    margin-bottom: 0 !important;
    font-size: 12px;
    line-height: 1.2;
    color: var(--text-secondary);
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical
}

.d-header .notification-dropdown .dropdown-menu .notification-items .notification-item ul li a>span {
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 14px;
    color: var(--text-primary)
}

.d-header .notification-dropdown .dropdown-menu .dropdown-menu-footer {
    border-top: 1px solid var(--color-border);
    padding: 15px 0;
    text-align: center;
    background-color: var(--color-white);
    z-index: 2;
    transition: .5s ease
}

.d-header .notification-dropdown .dropdown-menu .dropdown-menu-footer:hover {
    background-color: var(--color-primary)
}

.d-header .notification-dropdown .dropdown-menu .dropdown-menu-footer:hover a {
    color: var(--color-white)
}

.d-header .notification-dropdown .dropdown-menu .dropdown-menu-footer a {
    font-size: 14px;
    transition: .5s ease;
    font-weight: 600;
    color: var(--color-primary)
}

.d-header .btn-icon {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    gap: 0
}

.d-header .btn-icon span {
    display: inline-flex;
    font-size: 14px;
    line-height: 1;
    margin-bottom: 0
}

.d-header .btn-icon i {
    font-size: 24px;
    color: var(--text-primary)
}

.d-header-left {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 30px
}

.d-header-left .sidebar-button {
    display: flex;
    flex-direction: column;
    gap: 6px;
    cursor: pointer;
    align-items: flex-start;
    justify-content: center;
    text-align: right;
    z-index: 9;
    position: relative;
    display: none
}

@media(max-width: 1199px) {
    .d-header-left .sidebar-button {
        display: flex
    }
}

.d-header-left .sidebar-button span {
    display: inline-block;
    width: 30px;
    height: 2px;
    border-radius: 3px;
    background-color: var(--color-dark);
    transition: .5s ease
}

.d-header-left .sidebar-button span:nth-child(2) {
    width: 20px
}

.d-header-left .sidebar-button span:last-child {
    width: 25px
}

.d-header-left .sidebar-button:hover span:nth-child(2) {
    width: 30px
}

.d-header-left .sidebar-button:hover span:nth-child(3) {
    width: 30px
}

.header-search {
    position: relative;
    border: 1px solid var(--color-border);
    border-radius: 50px;
    overflow: hidden;
    flex-grow: 1
}

.header-search .icon {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 40px;
    height: 100%;
    background-color: rgba(0, 0, 0, 0);
    display: flex;
    justify-content: center;
    align-items: center
}

.header-search .icon i {
    vertical-align: middle;
    line-height: 1;
    font-size: 15px
}

.header-search input,
.header-search textarea {
    border-radius: 50px;
    padding: 8px 20px 8px 45px;
    border: unset
}

.header-search input::-moz-placeholder,
.header-search textarea::-moz-placeholder {
    font-size: 16px
}

.header-search input::placeholder,
.header-search textarea::placeholder {
    font-size: 16px
}

@keyframes DropDownSlide {
    100% {
        margin-top: 3px
    }

    0% {
        margin-top: 18px
    }
}

.main-content {
    background-color: var(--bg-light);
    width: calc(100% - 280px);
    margin-left: auto;
    min-height: 100vh;
    padding: 25px;
    transition: .4s;
    position: relative
}

@media(max-width: 1199px) {
    .main-content {
        width: 100%;
        margin-left: 0;
        padding: 15px
    }
}

@media(min-width: 768px)and (max-width: 991px) {
    .main-content {
        transition: unset
    }
}

[data-sidebar=close] .main-content {
    width: 100%
}

.main-content .page-title-box {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 5px;
    margin-bottom: 20px
}

.main-content .page-title-box .page-title {
    font-size: 24px
}

@media(max-width: 767px) {
    .main-content .page-title-box .page-title {
        font-size: 22px
    }
}

.main-content .page-title-box .page-title-right .breadcrumb .breadcrumb-item {
    font-size: 14px;
    line-height: 1
}

.main-content .page-title-box .page-title-right .breadcrumb .breadcrumb-item:not(:first-child)::before {
    color: unset;
    content: "";
    font-family: "Bootstrap-icons";
    font-size: 12px;
    vertical-align: middle
}

.main-content .page-title-box .page-title-right .breadcrumb .breadcrumb-item:not(a) {
    color: var(--text-secondary)
}

.main-content .page-title-box .page-title-right .breadcrumb .breadcrumb-item a {
    color: var(--text-primary);
    line-height: 1
}

.i-card-sm {
    position: relative;
    background-color: var(--color-white);
    padding: 30px;
    border-radius: 4px
}

@media(max-width: 767px) {
    .i-card-sm {
        padding: 15px
    }
}

.i-card-sm .icon {
    transition: .5s ease
}

.i-card-sm .title {
    margin-bottom: 15px;
    font-size: 21px
}

@media(max-width: 767px) {
    .i-card-sm .title {
        font-size: 19px
    }
}

.i-card-sm .subtitle {
    margin-bottom: 15px;
    font-size: 18px
}

.i-card-sm.style-2 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between
}

.i-card-sm.style-2 .card-info {
    text-align: left
}

.i-card-sm.style-2 .card-info .title {
    margin-bottom: 10px
}

.i-card-sm.style-2 .card-info p {
    margin-bottom: 0
}

.i-card-sm.style-2 .icon {
    min-width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    text-align: center
}

.i-card-sm.style-2 .icon i {
    font-size: 32px;
    opacity: .45;
    vertical-align: middle;
    line-height: 1
}

.i-card-sm.white .icon {
    background-color: var(--color-primary-light-2)
}

.i-card-sm.white .icon i {
    color: var(--color-primary);
    opacity: 1
}

.i-card-sm.white:hover .icon {
    background-color: var(--color-primary)
}

.i-card-sm.white:hover .icon i {
    color: var(--color-white)
}

.i-card-sm.green {
    background-color: var(--color-green-light)
}

.i-card-sm.green .icon {
    background-color: var(--color-green-light)
}

.i-card-sm.green .icon i {
    color: var(--color-green);
    opacity: 1
}

.i-card-sm.green:hover .icon {
    background-color: var(--color-green)
}

.i-card-sm.green:hover .icon i {
    color: var(--color-white)
}

.i-card-sm.purple {
    background-color: var(--color-purple-light)
}

.i-card-sm.purple .icon {
    background-color: var(--color-purple-light)
}

.i-card-sm.purple .icon i {
    color: var(--color-purple);
    opacity: 1
}

.i-card-sm.purple:hover .icon {
    background-color: var(--color-purple)
}

.i-card-sm.purple:hover .icon i {
    color: var(--color-white)
}

.i-card-sm.blue {
    background-color: var(--color-blue-light)
}

.i-card-sm.blue .icon {
    background-color: var(--color-blue-light)
}

.i-card-sm.blue .icon i {
    color: var(--color-blue);
    opacity: 1
}

.i-card-sm.blue:hover .icon {
    background-color: var(--color-blue)
}

.i-card-sm.blue:hover .icon i {
    color: var(--color-white)
}

.bot-info {
    padding-left: 25px;
    border-left: 1px solid var(--color-border)
}

.bot-info span {
    display: inline-block;
    color: var(--text-secondary);
    font-size: 15px;
    margin-bottom: 10px
}

.bot-info h6 {
    font-size: 24px
}

@media(min-width: 768px)and (max-width: 991px) {
    .bot-info h6 {
        font-size: 22px
    }
}

@media(max-width: 767px) {
    .bot-info h6 {
        font-size: 20px
    }
}

ul.bot-list li {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px
}

ul.bot-list li img {
    max-width: 120px;
    border: 1px solid #eee
}

ul.bot-list li:last-child {
    margin-bottom: 0
}

ul.bot-list .bot-info-single span {
    display: inline-block;
    color: var(--text-secondary);
    font-size: 15px;
    line-height: 1
}

@media(max-width: 767px) {
    ul.bot-list .bot-info-single span {
        font-size: 14px
    }
}

ul.bot-list .bot-info-single p {
    margin-bottom: 0;
    color: var(--text-primary);
    font-weight: 400;
    font-size: 16px
}

@media(max-width: 767px) {
    ul.bot-list .bot-info-single p {
        font-size: 15px
    }
}

.total-balance-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 100px;
    margin-top: 20px;
    margin-bottom: 25px;
    border-bottom: 1px solid var(--color-border);
    padding-bottom: 25px
}

.total-balance-wrapper .total-balance p {
    font-size: 15px;
    margin-bottom: 5px
}

.total-balance-wrapper .total-balance span {
    display: inline-block;
    font-size: 20px
}

@media(max-width: 767px) {
    .total-balance-wrapper .total-balance span {
        font-size: 18px
    }
}

.total-balance-wrapper .total-balance h4 {
    margin-bottom: 0
}

@media(max-width: 767px) {
    .total-balance-wrapper .total-balance h4 {
        font-size: 22px
    }
}

.arrow--btn {
    font-weight: 400;
    transition: .4s ease;
    height: 32px;
    line-height: 32px;
    padding: 0 10px;
    line-height: 1.5
}

.arrow--btn:hover {
    color: var(--color-primary)
}

.arrow--btn:hover i {
    color: inherit
}

.arrow--btn i {
    font-size: 22px;
    line-height: 1;
    vertical-align: middle
}

.i-card-md {
    position: relative;
    background-color: var(--card-bg);
    border-radius: 4px
}

.i-card-md .card-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 25px 0
}

.i-card-md .card-header .card-title {
    font-size: 18px;
    color: var(--text-primary)
}

.i-card-md .card-body {
    padding: 25px
}

.i-dropdown {
    position: relative;
    max-width: 100%;
    display: inline-block;
    min-width: 20px;
    margin: 0px 5px
}

.i-dropdown button {
    border-radius: 3px;
    background-color: rgba(0, 0, 0, 0);
    padding: 6px 10px 6px 10px;
    font-size: 15px;
    border-radius: 30px;
    color: var(--text-secondary)
}

.i-dropdown button::after {
    content: "";
    font-family: "Bootstrap-icons";
    font-weight: 400;
    border: none;
    top: 5px;
    font-size: 12px;
    position: relative
}

.i-dropdown .dropdown-menu {
    padding: 0;
    transition: .4s ease;
    border: 1px solid var(--color-border);
    border-radius: 0px
}

.i-dropdown .dropdown-menu.show {
    animation: appear .55s linear
}

@keyframes appear {
    0% {
        opacity: 0
    }

    100% {
        opacity: 1
    }
}

.i-dropdown .dropdown-item {
    font-size: 13px;
    padding: 4px 10px;
    margin-bottom: 1px
}

.i-dropdown .dropdown-item i {
    margin-right: 5px;
    font-size: 16px
}

.i-dropdown .dropdown-item:active {
    background-color: var(--color-primary)
}

.user-dropdown {
    width: auto
}

.user-dropdown .dropdown-menu {
    width: 12rem;
    position: relative;
    padding: 0 !important;
    border-radius: 0 !important
}

.user-dropdown .dropdown-menu>li:first-child span {
    display: inline-block;
    width: 100%;
    padding: .5rem 2rem;
    font-size: 16px;
    margin-bottom: .5rem;
    background-color: var(--color-primary-light);
    color: var(--color-primary);
    border-bottom: 1px solid var(--color-primary-light-2)
}

.user-dropdown .dropdown-item {
    padding: .5rem 1rem;
    display: flex;
    align-items: center;
    gap: 1rem
}

.user-dropdown-meta {
    padding: 0;
    cursor: pointer;
    display: flex !important;
    align-items: center;
    flex-direction: row;
    gap: 3px
}

.user-img {
    width: 2.5rem;
    aspect-ratio: 1/1;
    cursor: pointer
}

@media(max-width: 767px) {
    .user-img {
        width: 2.2rem
    }
}

.user-img>img {
    width: 100%
}

.user-dropdown-info {
    display: none;
    visibility: hidden
}

.user-meta {
    background-color: var(--color-white);
    padding: 25px
}

.user-meta .user {
    border-right: 1px solid var(--color-border)
}

@media(max-width: 991px) {
    .user-meta .user {
        border-right: unset
    }
}

.user-meta .meta-info {
    border-right: 1px solid var(--color-border)
}

@media(max-width: 767px) {
    .user-meta .meta-info {
        border-right: unset
    }
}

.user-meta .meta-info span {
    display: inline-block;
    color: var(--text-secondary);
    font-size: 15px;
    margin-bottom: 0px
}

@media(max-width: 767px) {
    .user-meta .meta-info span {
        font-size: 14px
    }
}

.user-meta .meta-info p {
    margin-bottom: 0;
    font-weight: 400;
    color: var(--text-primary);
    font-size: 18px
}

@media(max-width: 767px) {
    .user-meta .meta-info p {
        font-size: 16px
    }
}

.user-meta .user {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 25px;
    flex-wrap: wrap
}

@media(max-width: 767px) {
    .user-meta .user {
        gap: 15px
    }
}

.user-meta .user .image {
    max-width: 80px
}

@media(max-width: 767px) {
    .user-meta .user .image {
        max-width: 60px
    }
}

.user-meta .user .content h5 {
    margin-bottom: 12px
}

@media(max-width: 767px) {
    .user-meta .user .content h5 {
        font-size: 17px
    }
}

.user-meta .user .content .social {
    display: flex;
    gap: 18px
}

@media(max-width: 767px) {
    .user-meta .user .content .social {
        gap: 12px
    }
}

.user-meta .user .content .social li a {
    font-size: 14px;
    min-width: 32px;
    height: 32px;
    line-height: 32px;
    text-align: center;
    border-radius: 50%;
    border: 1px solid var(--color-border);
    display: inline-block;
    transition: .4s ease;
    color: var(--text-secondary)
}

.user-meta .user .content .social li a:hover {
    background-color: var(--color-primary);
    color: var(--color-white);
    border: 1px solid var(--color-primary)
}

.bot-card {
    border: 1px solid #eee;
    transition: .5s ease;
    z-index: 1;
    overflow: hidden
}

.bot-card:hover {
    background-color: var(--color-primary-light-2);
    border: 1px solid var(--color-primary-light-2)
}

.bot-card .title {
    margin-bottom: 3px
}

@media(max-width: 767px) {
    .bot-card .title {
        font-size: 18px
    }
}

.bot-card .bot-tag {
    color: var(--color-primary);
    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    font-size: 14px
}

.bot-card::after {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    width: 50px;
    height: 50px;
    background: var(--color-primary-light-2);
    border-radius: 0 0 0 100px;
    z-index: -1
}

.bot-card .card--header {
    border-bottom: 1px solid #eee;
    padding-bottom: 15px;
    margin-bottom: 15px
}

.bot-card .card--header .icon span {
    display: inline-block;
    font-weight: 400;
    color: var(--text-primary)
}

.bot-card .card--header .icon i {
    color: var(--color-white);
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    border-radius: 50%;
    background-color: var(--color-primary);
    display: inline-block;
    font-size: 18px
}

.payment-process-wrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    position: relative;
    gap: 10px;
    position: relative;
    z-index: 1
}

@media(max-width: 1199px) {
    .payment-process-wrapper {
        justify-content: flex-start
    }
}

.payment-process-wrapper::after {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    height: 1px;
    background-color: rgba(0, 0, 0, 0);
    border-top: 2px dashed var(--color-border);
    z-index: -1
}

@media(max-width: 1199px) {
    .payment-process-wrapper::after {
        content: unset
    }
}

.payment-process-wrapper .process-two {
    text-align: center;
    background-color: var(--color-white);
    border: 1px solid var(--color-border);
    padding: 15px;
    min-width: 130px;
    border-radius: 10px;
    position: relative
}

@media(max-width: 576px) {
    .payment-process-wrapper .process-two {
        min-width: 100%;
        max-width: 100%
    }
}

.payment-process-wrapper .process-two .icon {
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    border: 1px solid var(--color-primary);
    background-color: var(--color-primary-light-2);
    margin-bottom: 15px;
    border-radius: 50%;
    margin-left: auto;
    margin-right: auto
}

.payment-process-wrapper .process-two .icon i {
    font-size: 30px;
    color: var(--color-primary)
}

.payment-process-wrapper .process-two h6 {
    margin-bottom: 0;
    color: var(--text-secondary)
}

.payment-form {
    margin-top: 30px
}

.payment-form input,
.payment-form textarea {
    height: 45px;
    line-height: 45px;
    font-size: 20px
}

.payment-form input::-moz-placeholder,
.payment-form textarea::-moz-placeholder {
    font-size: 22px;
    color: var(--color-border)
}

.payment-form input::placeholder,
.payment-form textarea::placeholder {
    font-size: 22px;
    color: var(--color-border)
}

.user-settings {
    background-color: var(--color-primary-light-2);
    padding: 0px;
    padding-bottom: 20px;
    padding: 25px
}

.user-settings .user {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 15px
}

.user-settings .user .image {
    overflow: hidden;
    max-width: 90px;
    position: relative;
    z-index: 1;
    cursor: pointer
}

.user-settings .user .image:hover .upload-overlay {
    opacity: 1
}

.user-settings .user .image .upload-overlay {
    z-index: 2;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, .7);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    transition: .5s ease;
    opacity: 0
}

.user-settings .user .image .upload-overlay h6 {
    color: var(--color-white);
    font-size: 14px;
    font-weight: 300;
    margin-bottom: 5px
}

.user-settings .user .image .upload-overlay i {
    color: var(--color-primary);
    font-size: 20px;
    line-height: 1;
    vertical-align: middle
}

.user-settings .user .image .image-upload {
    z-index: 3;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    opacity: 0
}

.user-settings .user .image .image-upload input,
.user-settings .user .image .image-upload textarea {
    width: 90px;
    cursor: pointer;
    height: 90px
}

.user-settings .user .content h6 {
    margin-bottom: 0px
}

.user-settings .user .content p {
    margin-bottom: 0;
    font-size: 15px
}

.user-form .form-inner {
    margin-bottom: 20px
}

.switch-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 15px;
    border-bottom: 1px solid var(--color-border);
    padding: 20px 0
}

.switch-wrapper:first-child {
    border-top: 1px solid var(--color-border)
}

.switch-wrapper h6 {
    color: var(--text-secondary)
}

.switch-wrapper p {
    margin-bottom: 0;
    font-size: 15px
}

.bot-form-wrapper {
    padding: 25px 25px 50px 25px;
    border: 1px solid var(--color-border)
}

@media(max-width: 767px) {
    .bot-form-wrapper {
        padding: 20px 20px 45px 20px
    }
}

.bot-form-wrapper .auto-fill {
    font-size: 16px;
    font-weight: 400;
    color: var(--color-primary);
    margin-bottom: 10px;
    cursor: pointer
}

.bot-form-wrapper input,
.bot-form-wrapper textarea {
    height: 40px;
    line-height: 40px
}

.bot-form-wrapper input[type=range],
.bot-form-wrapper textarea[type=range] {
    height: 5px;
    padding: 0;
    border: none
}

.bot-form-wrapper .input--group {
    position: relative;
    height: 40px;
    line-height: 40px;
    border: 1px solid var(--color-border);
    border-radius: 5px
}

.bot-form-wrapper .input--group input,
.bot-form-wrapper .input--group textarea {
    padding: 0 60px
}

.bot-form-wrapper .input--group span {
    display: inline-block;
    font-size: 14px;
    padding: 5px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%)
}

.bot-form-wrapper .input--group .start {
    left: 10px
}

.bot-form-wrapper .input--group .end {
    right: 10px
}

.range-bar-wrap {
    position: relative;
    z-index: 1;
    margin-top: 20px
}

.range-bar-wrap input[type=range],
.range-bar-wrap textarea[type=range] {
    z-index: 1
}

.range-bar-wrap .range-list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    top: 2px;
    width: 100%;
    z-index: -1;
    cursor: auto
}

.range-bar-wrap .range-list li {
    font-size: 13px;
    text-align: center;
    font-weight: 400
}

.range-bar-wrap .range-list li span {
    display: block;
    width: 12px;
    height: 12px;
    margin-left: auto;
    margin-right: auto;
    transform: rotate(45deg) translate(-7px);
    background-color: rgba(0, 0, 0, 0);
    border: 5px solid var(--color-border);
    position: relative
}

#rangeValue {
    position: relative;
    display: block;
    text-align: center;
    font-size: 16px;
    color: #999;
    font-weight: 400
}

.range {
    width: 100%;
    height: 5px;
    -webkit-appearance: none;
    background: var(--color-border);
    outline: none;
    border-radius: 0px
}

.range::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 10px;
    height: 10px;
    border-radius: 0%;
    background: var(--color-primary);
    cursor: pointer;
    transform: rotate(45deg)
}

footer {
    position: relative;
    background-color: var(--color-dark);
    z-index: 1
}

footer .footer-social {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 25px
}

@media(max-width: 991px) {
    footer .footer-social {
        justify-content: center;
        margin-bottom: 15px
    }
}

footer .footer-social li a {
    color: var(--text-light);
    transition: .4s ease
}

footer .footer-social li:hover a {
    color: var(--color-primary)
}

footer .footer-item {
    text-align: left;
    color: var(--color-white)
}

footer .footer-item>h4 {
    margin-bottom: 20px;
    font-size: 22px
}

footer .footer-item p {
    color: var(--text-light);
    font-size: 15px
}

footer .footer-title {
    font-size: 22px;
    font-weight: 400;
    color: var(--color-white);
    line-height: 1.5;
    position: relative;
    margin-bottom: 10px;
}

footer .footer-title::before {
    content: "";
    position: absolute;
    left: -15px;
    top: -5px;
    width: 40px;
    height: 40px;
    background-color: rgba(255, 255, 255, .1);
    border-radius: 50%
}

@media(max-width: 767px) {
    footer .footer-title {
        font-size: 18px
    }
}

footer .footer-menu li {
    margin-bottom: 12px
}

footer .footer-menu li:last-child {
    margin-bottom: 0px
}

footer .footer-menu li a {
    color: var(--text-primary);
    display: inline-block;
    color: var(--text-light);
    cursor: pointer;
    transition: .4s ease
}

footer .footer-menu li a:hover {
    color: var(--color-primary)
}

footer .footer-menu li a:hover i {
    color: var(--color-primary);
    margin-right: 10px
}

footer .footer-menu li a i {
    font-size: 15px;
    margin-right: 5px;
    transition: .4s ease;
    line-height: 1
}

footer .footer-bottom {
    margin-top: 80px;
    padding: 15px 0 15px;
    border-top: 1px solid rgba(255, 255, 255, .1)
}
@media(max-width: 767px) {
    footer .footer-bottom {
        margin-top: 50px;
    }
}

footer .footer-bottom p {
    color: var(--text-light);
    font-size: 13px;
    margin-bottom: 0
}

footer p {
    color: var(--text-light);
    font-size: 15px
}
.payment-logos{
    width: 100%;
    max-width: 300px;
}

.footer-address-wrapper {
    position: relative;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    z-index: 1;
    flex-wrap: nowrap;
    gap: 25px
}

@media(max-width: 991px) {
    .footer-address-wrapper {
        flex-wrap: wrap
    }
}

.footer-address-wrapper .address-item {
    text-align: left
}
.footer-address-wrapper .address-item .address{
    font-size: 14px;
}

.footer-address-wrapper .address-item h6 {
    font-size: 15px;
    color: var(--color-white);
    margin-bottom: 6px;
    text-transform: uppercase
}

.footer-address-wrapper .address-item .address {
    text-decoration: none;
    color: var(--text-light);
    font-size: 16px
}

.footer-logo {
    max-width: 180px
}

@media(max-width: 991px) {
    .footer-logo {
        max-width: 150px
    }
}

.footer-big-title h3 {
    font-size: 45px;
    color: var(--color-white)
}

@media(min-width: 768px)and (max-width: 991px) {
    .footer-big-title h3 {
        font-size: 40px
    }
}

@media(max-width: 767px) {
    .footer-big-title h3 {
        font-size: 32px
    }
}

.input-wrapper {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: center;
    -webkit-backdrop-filter: blur(3px);
    backdrop-filter: blur(3px);
    padding: 1px;
    overflow: hidden;
    border-radius: 0px
}

.input-wrapper.style-two input,
.input-wrapper.style-two textarea {
    background-color: var(--bg-light);
    border: 1px solid var(--color-border);
    border-radius: 30px 0 0 30px;
    color: var(--text-primary)
}

.input-wrapper.style-two input:focus,
.input-wrapper.style-two textarea:focus {
    border: 1px solid var(--color-primary)
}

.input-wrapper.style-two button {
    border-radius: 0 30px 30px 0;
    min-width: 60px
}

.input-wrapper:hover button i {
    transform: rotate(45deg)
}

.input-wrapper input,
.input-wrapper textarea {
    border: none;
    height: 45px;
    line-height: 45px;
    border: none;
    color: var(--color-white);
    padding: 0 15px;
    font-size: 16px;
    background-color: var(--color-dark);
    border-bottom: 1px solid rgba(255,255,255,0.45);
    transition: .4s ease;
    border-radius: 0px
}

.input-wrapper input:focus,
.input-wrapper textarea:focus {
    border-bottom: 1px solid var(--color-primary)
}

.input-wrapper input::-moz-placeholder,
.input-wrapper textarea::-moz-placeholder {
    color: var(--text-light)
}

.input-wrapper input::placeholder,
.input-wrapper textarea::placeholder {
    color: var(--text-light)
}

@media(max-width: 767px) {

    .input-wrapper input,
    .input-wrapper textarea {
        height: 45px
    }
}

.input-wrapper button {
    background-color: var(--color-primary);
    color: var(--text-primary);
    height: 45px;
    line-height: 1;
    display: inline-flex;
    min-width: 80px;
    justify-content: center;
    align-items: center;
    padding: 10px 15px;
    border-radius: 50px
}

@media(max-width: 767px) {
    .input-wrapper button {
        height: 45px;
        min-width: 50px
    }
}

.input-wrapper button i {
    margin-left: 5px;
    font-size: 18px;
    transition: .3s ease;
    color: var(--color-white)
}

.footer-vector {
    position: absolute;
    top: 0%;
    transform: translateY(50%);
    right: 10px;
    max-width: 150px;
    opacity: .1
}

#smooth-content {
    overflow: visible;
    width: 100%
}

.banner-section {
    padding: 200px 5% 120px 5%;
    position: relative;
    z-index: 1;
    overflow: hidden;
    background-color: #161513;
}
.banner-features{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 25px;
    margin-top: 55px;
}
.banner-features li{
    font-size: 16px;
    font-weight: 400;
    color: var(--color-white);
}
.banner-features li span {
    display: inline-flex;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    background: linear-gradient(40deg, var(--color-primary-light), rgba(255,255,255,0.1));
    margin-right: 10px;
}
.banner-features li span i{
    font-size: 22px;
    color: var(--color-primary);
}
.banner-bg{
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
    max-width: 1230px;
    margin-right: 0;
    margin-left: auto;
}
.banner-bg img{
    max-width: 100%;
}
@media (max-width: 768px) {
    .banner-section {
        padding: 120px 0% 120px 0%;
    }
}
.banner-section .linear-big {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -40%;
    width: 600px;
    height: 600px;
    border-radius: 50%;
    background-color: var(--color-primary-light);
    filter: blur(40px);
    z-index: -1
}
.banner-btn{
    background-color: var(--color-primary);
    color: var(--color-white);
    font-size: 18px;
    font-weight: 400;
    padding: 15px 65px 15px 20px;
    border-radius: 50px;
    position: relative;
}
@media (max-width: 768px) {
    .banner-btn{
        font-size: 16px;
        padding: 15px 50px 15px 20px;
    }
}
.banner-btn i{
    position: absolute;
    top: 50%;
    right: 0;
    height: 50px;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255,255,255,0.3);
    color: var(--color-white);
    border-radius: 50%;
    font-size: 28px;
    transform: translateY(-50%) rotate(-45deg);
    transition: 0.4s ease;
    transform-origin: center;
}
@media (max-width: 768px) {
    .banner-btn i{
        height: 40px;
        width: 40px;
    }
}
.banner-btn:hover i{
    transform: translateY(-50%) rotate(0deg);
}

.banner-content{
    background-color: rgba(255,255,255,0.085);
    border-radius: 30px;
    padding: 18px;
    position: relative;
}

@media (max-width: 768px) {
    .providers img{
        max-width: 90px;
        opacity: 0.7;
    }
}
.global-users{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
    background-color: #161513;
    padding: 15px 15px;
    border-radius: 30px 0 30px 0;
    width: 100%;
    max-width: 400px;
    transform: translate(18px, 18px);
    position: relative;
}
.global-users .shape-left{
    position: absolute;
    bottom: 0;
    left: -25px;
}
.global-users .shape-right{
    position: absolute;
    top: -25px;
    right: 0px;
}
.global-users .shape-right svg{
    fill: #161513;
    height: 25px;
    width: 25px;
}
.global-users .shape-left svg{
    fill: #161513;
    height: 25px;
    width: 25px;
}

.global-users .icon svg{
    width: 28px;
    height: 28px;
    fill: var(--color-white);
    opacity: 0.65;
}
@media (max-width: 991px) {
    .global-users .icon {
        display: none;
        visibility: hidden;
    }
}
.global-users .text p{
    margin-bottom: 0;
    font-size: 14px;
    line-height: 1.2;
}

.banner-content h1{
    font-size: 60px;
    color: var(--color-white);
    line-height: 1;
    margin-bottom: 20px;
    margin-top: -10px;
    font-weight: 400;
}
@media (min-width: 991px) and (max-width: 1200px){
    .banner-content h1{
        font-size: 45px;
    }
}

@media (max-width: 768px) {
    .banner-content h1{
        font-size: 40px;
    }
}
.banner-content h1 span{
    font-size: 70px;
    display: inline-block;
    background: linear-gradient(to right, var(--color-primary) 0%, var(--color-white) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
@media (max-width: 768px) {
    .banner-content h1 span{
        font-size: 40px;
    }
}
.banner-content p{
    font-size: 18px;
    color: rgba(255,255,255,0.7);
    margin-bottom: 25px;
}
@media (max-width: 768px) {
    .banner-content p{
        font-size: 15px;
    }
}
.linear-right {
    position: absolute;
    right: 0%;
    bottom: 0%;
    width: 550px;
    height: 550px;
    border-radius: 50%;
    background-color: var(--color-primary-light);
    filter: blur(140px);
    z-index: -1
}

@media(max-width: 991px) {
    .linear-right {
        width: 250px;
        height: 250px
    }
}

.linear-left {
    position: absolute;
    left: -90px;
    top: 90px;
    width: 550px;
    height: 550px;
    border-radius: 50%;
    background-color: var(--color-primary-light);
    filter: blur(140px);
    z-index: -1
}

@media(max-width: 991px) {
    .linear-left {
        width: 250px;
        height: 250px
    }
}

.linear-center {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 650px;
    transform: translate(-50%, -50%);
    height: 650px;
    border-radius: 50%;
    background-color: var(--color-primary-light);
    filter: blur(140px);
    z-index: -1
}

@media(max-width: 991px) {
    .linear-center {
        width: 250px;
        height: 250px
    }
}

.banner-info {
    position: relative;
    z-index: 1
}
.shine {
    position: absolute;
    right: 100px;
    top: -30px;
    opacity: .07;
    z-index: -1;
    max-width: 60px;
    animation: updown 3s linear infinite alternate
}
.shine svg {
    fill: var(--text-primary);
    max-width: 60px;
    height: 60px
}

@keyframes updown {
    0% {
        transform: translateY(20px)
    }

    100% {
        transform: translateY(0px)
    }
}

.banner-info>span {
    display: inline-block;
    font-size: 15px;
    color: var(--color-primary);
    margin-bottom: 10px;
    text-transform: uppercase
}

@media(max-width: 767px) {
    .banner-info>span {
        font-size: 16px
    }
}

.banner-info h1 {
    font-size: 75px;
    color: var(--text-primary);
    margin-bottom: 35px;
    line-height: 1.3;
    font-weight: 500;
    margin-top: -12px;
}
.banner-graph{
    position: relative;
    z-index: 1;
}

.banner-graph img {
    max-height: 370px;
    width: 100%;
    object-fit: cover;
}

@media(min-width: 1200px)and (max-width: 1399px) {
    .banner-info h1 {
        font-size: 55px
    }
}

@media(max-width: 767px) {
    .banner-info h1 {
        font-size: 40px
    }
}

@media(min-width: 768px)and (max-width: 991px) {
    .banner-info h1 {
        font-size: 42px
    }
}

@media(min-width: 992px)and (max-width: 1199px) {
    .banner-info h1 {
        font-size: 50px
    }
}

.banner-info h1 i {
    font-size: 75px;
    color: var(--text-secondary);
    margin-right: 10px
}

@media(max-width: 767px) {
    .banner-info h1 i {
        display: none;
        visibility: hidden
    }
}

.banner-info p {
    color: var(--text-secondary);
    margin-bottom: 50px
}

@media(max-width: 767px) {
    .banner-info p {
        margin-bottom: 35px
    }
}

.banner-image {
    position: relative;
    background-color: var(--color-primary);
    box-shadow: 0 0 10px rgba(0, 0, 0, .2);
    border-radius: 5px;
    overflow: hidden;
    border: 10px solid var(--color-white)
}

.banner-image #draw {
    cursor: pointer;
    left: 0;
    position: absolute;
    top: 0%;
    width: 10%;
    padding: 15px 10px;
    border-radius: 20px;
    margin: 20px;
    background: #f79a7a;
    border: 4px solid #fff;
    text-transform: uppercase;
    letter-spacing: 4px;
    color: #fff
}

.banner-image #draw:focus {
    outline: none
}

.banner-image #svg {
    top: 60%;
    left: 50%;
    width: 30%;
    height: 80%;
    position: relative;
    -webkit-transform: translate3d(-50%, -50%, 0)
}

.banner-image .line {
    stroke-dashoffset: 0;
    box-shadow: 10px 10px 1px #000
}

.banner-image .breakpoint {
    transform: scale(0);
    position: absolute;
    -webkit-transform-origin: 50% 50%;
}

.banner-image .active {
    transform: scale(1)
}

.big-badge-outline{
    padding: 7px 25px;
    border-radius: 30px;
    border: 2px solid var(--color-border);
    font-size: 20px;
    color: var(--text-primary);
    font-weight: 500;
}
@media (max-width: 768px){
    .big-badge-outline{
        font-size: 16px;;
    }
}

.view-hexa-btn{
    position: relative;
    line-height: 1;
    transition: 0.5s;
    height: 178px;
    width: 178px;
    display: inline-flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: 0;
    margin-left: auto;
}
@media (max-width: 768px){
    .view-hexa-btn{
        height: 120px;
        width: 120px;
    }
}
.view-hexa-btn .hexa-btn-content{
    position: absolute;
    top: 50%;
    left:50%;
    transform: translate(-50%, -50%);
    transition: 0.5s;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    font-size: 18px;
    color: var(--text-primary);
    font-weight: 400;
    line-height: 1.5;
    z-index: 2;
}
@media (max-width: 768px){
    .view-hexa-btn .hexa-btn-content{
        font-size: 16px;
    }
}

.view-hexa-btn svg{
    fill: var(--color-white);
    stroke: var(--text-secondary);
    transition: 0.5s;
}
.view-hexa-btn:hover svg{
    fill: var(--text-primary);
    stroke: var(--text-primary);
}
.view-hexa-btn:hover .hexa-btn-content {
    color: var(--color-white);
}

.choose-us-section {
    position: relative
}

.choose-us-section .graph-two {
    position: absolute;
    left: 10px;
    top: 90px;
    max-width: 150px;
    z-index: 2;
    opacity: .25
}

.choose-us-section .graph-two svg {
    fill: var(--text-light);
    width: 100%
}

.choose-img {
    height: 100% !important;
    width: 100% !important
}

.choose-img img {
    width: 100% !important;
    height: 100% !important;
    -o-object-fit: cover;
    object-fit: cover
}

.pl--continer {
    padding-left: calc((100% - 1320px)/2)
}

@media(min-width: 1200px)and (max-width: 1399px) {
    .pl--continer {
        padding-left: calc((100% - 1140px)/2)
    }
}

@media(min-width: 1200px)and (max-width: 1399px) {
    .pl--continer {
        padding-left: calc((100% - 1140px)/2)
    }
}

@media(min-width: 992px)and (max-width: 1199px) {
    .pl--continer {
        padding-left: calc((100% - 960px)/2);
        padding-right: calc((100% - 960px)/2)
    }
}

@media(min-width: 768px)and (max-width: 991px) {
    .pl--continer {
        padding-left: calc((100% - 720px)/2);
        padding-right: calc((100% - 720px)/2)
    }
}

@media(min-width: 576px)and (max-width: 768px) {
    .pl--continer {
        padding-left: calc((100% - 540px)/2);
        padding-right: calc((100% - 540px)/2)
    }
}

@media(max-width: 576px) {
    .pl--continer {
        padding-right: calc(var(--bs-gutter-x)*.5);
        padding-left: calc(var(--bs-gutter-x)*.5)
    }
}

.bet-vecotr {
    margin-top: 60px;
    max-width: 250px;
    opacity: .18
}

@media(max-width: 991px) {
    .bet-vecotr {
        margin-top: 30px;
        max-width: 160px;
    }
}

.bet-vecotr.style-two {
    opacity: .07
}

.bet-vecotr img {
    max-width: 100%
}

.about-us-section {
    position: relative;
    z-index: 1
}

.about-image {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center
}

.about-image img {
    -o-object-fit: cover;
    object-fit: cover
}

@media(max-width: 991px) {
    .about-image {
        display: none;
        visibility: hidden
    }
}

.about-image .about-1 {
    position: absolute;
    top: -90px;
    left: 0px;
    border-radius: 0 0px 0px 0
}

.about-image .about-2 {
    position: absolute;
    top: 150px;
    left: 140px;
    border-radius: 0px 0 0 0px
}

@media(max-width: 1199px) {
    .about-image .about-2 {
        left: 100px
    }
}

.about-image img {
    max-width: 320px;
    border-radius: 20px
}

@media(min-width: 992px)and (max-width: 1199px) {
    .about-image img {
        max-width: 300px
    }
}

.about-list {
    margin: 0;
    padding: 0
}

.about-list li {
    margin-bottom: 15px;
    display: flex;
    justify-content: flex-start;
    gap: 8px
}

.about-list li span {
    display: inline-block;
    font-size: 16px;
    font-weight: 400
}

.about-list li:last-child {
    margin-bottom: 0
}

.about-list li i {
    color: var(--color-primary);
    font-size: 22px
}

.newsletter-box {
    position: relative;
    z-index: 2
}

.newsletter-box p {
    color: var(--text-light);
    margin-bottom: 20px
}

.newsletter-section {
    z-index: 9
}

.testimonial-section {
    position: relative
}

.testimonial-section .quote {
    position: absolute;
    top: 90px;
    right: 20px;
    width: 120px;
    height: 120px;
    line-height: 120px;
    border-radius: 50%;
    background-color: var(--color-primary);
    text-align: center;
    z-index: 99;
    opacity: .15
}

@media(min-width: 1200px)and (max-width: 1399px) {
    .testimonial-section .quote {
        width: 120px;
        height: 120px
    }
}

@media(max-width: 767px) {
    .testimonial-section .quote {
        width: 90px;
        height: 90px;
        line-height: 90px;
        top: 120px
    }
}

.testimonial-section .quote i {
    color: var(--color-white);
    font-size: 90px
}

@media(min-width: 1200px)and (max-width: 1399px) {
    .testimonial-section .quote i {
        font-size: 90px
    }
}

@media(max-width: 767px) {
    .testimonial-section .quote i {
        font-size: 60px
    }
}

.testimonial-section .swiper-slide {
    opacity: .35
}

.testimonial-section .swiper-slide-active {
    opacity: 1
}

.inner-banner {
    padding: 140px 10px 60px 10px;
    position: relative;
    z-index: 1;
    overflow: hidden;
    background: linear-gradient(rgba(0,0,0,0.95),rgba(0,0,0,0.95));
}

@media (max-width: 1199px) {
    .inner-banner {
        padding: 120px 10px 60px 10px;
    }
}

.inner-banner .bear-bull {
    position: absolute;
    left: 0%;
    bottom: 0;
    max-width: 280px;
    opacity: .09;
    z-index: -1
}

.inner-banner-title {
    color: var(--color-white);
    font-size: 40px;
    margin-bottom: 12px
}

@media(max-width: 767px) {
    .inner-banner-title {
        font-size: 32px
    }
}

@media(min-width: 768px)and (max-width: 991px) {
    .inner-banner-title {
        font-size: 36px
    }
}

@media(min-width: 992px)and (max-width: 1199px) {
    .inner-banner-title {
        font-size: 38px
    }
}

.breadcrumb {
    justify-content: flex-start;
    margin-bottom: 0
}

.breadcrumb .breadcrumb-item {
    color: var(--color-white);
    padding-left: 0
}

.breadcrumb .breadcrumb-item a {
    color: var(--color-white);
    opacity: 0.8;
}

.breadcrumb-item+.breadcrumb-item::before {
    float: left;
    padding-right: var(--bs-breadcrumb-item-padding-x);
    color: var(--text-light);
    content: var(--bs-breadcrumb-divider, "../../../../index.html")
}

.nav-tabs {
    display: flex;
    flex-direction: column
}

.nav-tabs .nav-link {
    color: var(--text-secondary);
    font-size: 16px;
    transition: .3s ease;
    padding: 3px 15px;
    font-weight: 500
}

.nav-tabs.style-1 {
    flex-direction: column;
    gap: 20px
}

.nav-tabs.style-1 .nav-link {
    border-radius: 0px;
    font-size: 18px;
    text-align: center;
    position: relative;
    color: var(--text-primary);
    z-index: 1
}

.nav-tabs.style-1 .nav-link svg {
    width: 18px;
    margin-left: 15px
}

[dir=rtl] .nav-tabs.style-1 .nav-link svg {
    transform: scaleX(-1);
    margin-left: unset;
    margin-right: 15px
}

.nav-tabs.style-1 .nav-link i {
    margin-right: 15px;
    font-size: 18px
}

.nav-tabs.style-1 .nav-link.active {
    color: var(--color-primary)
}

.gs_reveal {
    opacity: 0;
    visibility: hidden;
    will-change: transform, opacity
}

.fade-item {
    opacity: 0
}

.counter-wrap {
    background-color: rgba(0, 0, 0, 0);
    position: relative;
    z-index: 99;
    margin-left: 0
}

.counter-single {
    border: 1px solid var(--color-border);
    padding: 12px 15px;
    border-radius: 90px
}

.counter-single h3 {
    font-size: 55px;
    line-height: 1;
    color: var(--color-primary-light);
    -webkit-text-stroke: 1px var(--color-primary);
    text-stroke: 1px var(--color-primary);
    margin-bottom: 0;
    line-height: 1
}

@media(min-width: 1200px)and (max-width: 1399px) {
    .counter-single h3 {
        font-size: 50px
    }
}

@media(min-width: 992px)and (max-width: 1199px) {
    .counter-single h3 {
        font-size: 40px
    }
}

@media(max-width: 767px) {
    .counter-single h3 {
        font-size: 40px
    }
}

.counter-single i {
    font-size: 50px;
    line-height: 1;
    color: #fff;
    -webkit-text-stroke: 1px var(--text-primary);
    text-stroke: 1px var(--text-primary)
}

@media(max-width: 767px) {
    .counter-single i {
        font-size: 20px
    }
}

.counter-single p {
    margin-bottom: 0;
    font-weight: 400;
    font-size: 18px;
    color: var(--text-primary)
}

.urgent-call {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 15px;
    margin-bottom: 60px
}

.urgent-call .icon {
    background-color: var(--color-primary);
    min-width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%
}

.urgent-call .icon svg {
    fill: var(--color-white);
    max-width: 60px;
    height: 60px;
}

.urgent-call h3 {
    font-size: 40px;
}

@media (max-width: 768px) {
    .urgent-call{
        flex-wrap: wrap;
    }
    .urgent-call h3 {
        font-size: 32px;
    }
}

.urgent-call p {
    margin-bottom: 0
}

.contact-image {
    margin-left: 40px
}

.contact-image img {
    max-width: 110%
}

@media(min-width: 1200px)and (max-width: 1399px) {
    .contact-image img {
        max-width: 100%
    }
}

@media(max-width: 1199px) {
    .contact-image img {
        max-width: 100%
    }
}

.blog-detials .image {
    margin-bottom: 30px;
    overflow: hidden
}

.blog-detials .meta-list {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 25px;
    gap: 10px;
}

.blog-detials .meta-list li {
    margin-right: 5px;
    position: relative
}

.blog-detials .meta-list li:last-child::after {
    content: unset
}

.blog-detials .meta-list li a {
    font-size: 15px;
    font-weight: 400;
    background-color: var(--bg-light);
    padding: 3px 14px;
    border-radius: 25px;
    color: var(--text-secondary)
}

.blog-detials .meta-list li a i {
    font-size: 13px;
    margin-right: 7px;
    vertical-align: middle;
    color: var(--text-primary)
}

.blog-detials .title {
    margin-bottom: 15px
}

.blog-detials .title h3 {
    font-size: 32px
}

@media(max-width: 767px) {
    .blog-detials .title h3 {
        font-size: 26px
    }
}

@media(min-width: 768px)and (max-width: 991px) {
    .blog-detials .title h3 {
        font-size: 26px
    }
}

@media(min-width: 992px)and (max-width: 1199px) {
    .blog-detials .title h3 {
        font-size: 28px
    }
}

.blog-detials p {
    margin-bottom: 20px
}

.blog-detials blockquote {
    position: relative;
    margin: 35px 0px;
    padding: 30px 30px;
    background-color: var(--bg-light)
}

@media (max-width: 768px) {
    .blog-detials blockquote {
        padding: 25px 25px;
    }
}

.blog-detials blockquote::after {
    content: "";
    font-family: "Bootstrap-icons";
    position: absolute;
    left: 0px;
    top: -10px;
    font-size: 40px;
    color: var(--text-secondary);
    opacity: .15
}

.blog-detials blockquote p {
    margin-bottom: 0px;
    font-size: 18px;
    font-style: italic;
    color: var(--text-primary);
    font-weight: 400;
}

@media(max-width: 767px) {
    .blog-detials blockquote p {
        font-size: 16px
    }
}

.blog-detials blockquote span {
    display: inline-block;
    font-size: 14px;
    font-weight: 600;
    color: var(--text-secondary);
    line-height: 1
}

.blog-share-area {
    margin-top: 50px;
    border-top: 1px solid var(--color-border);
    border-bottom: 1px solid var(--color-border);
    padding: 20px 0
}

.blog-share-area .blog-share {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end
}

.blog-share-area .blog-share h6 {
    margin-bottom: 0;
    margin-right: 10px
}

.blog-share-area .blog-share a {
    text-decoration: none;
    color: var(--text-secondary);
    font-size: 14px
}

.blog-tag-area {
    display: flex;
    justify-content: start;
    align-items: flex-start;
    width: 100%
}

.blog-tag-area h6 {
    font-family: var(--font-merriw);
    margin: 0;
    margin-right: 10px
}

.tag-list {
    display: flex;
    justify-content: start;
    flex-wrap: wrap;
    gap: 15px
}

.tag-list li a {
    font-size: 15px;
    font-weight: 400;
    transition: all .4s ease;
    line-height: 1;
    background-color: var(--bg-light);
    padding: 5px 18px;
    border-radius: 30px;
    color: var(--text-secondary)
}

.tag-list li a:hover {
    background-color: var(--color-primary);
    color: var(--color-white)
}

.subtitle {
    margin-bottom: 18px;
    font-size: 26px
}

@media(max-width: 767px) {
    .subtitle {
        font-size: 24px
    }
}

.signle-comment {
    padding-top: 30px;
    padding-bottom: 30px;
    background-color: var(--bg-light);
    padding: 25px;
    margin-bottom: 30px
}

@media(max-width: 991px) {
    .signle-comment {
        padding: 20px
    }
}

.signle-comment .comment-header {
    margin-bottom: 15px
}

.signle-comment .comment-header .comment-image {
    max-width: 45px;
    height: 45px;
    width: 100%;
    margin-right: 15px;
    border-radius: 50%;
    overflow: hidden
}

.signle-comment .comment-header .comment-image img {
    max-width: 100%
}

.signle-comment .comment-header h5 {
    font-size: 16px;
    font-weight: 500;
    color: var(--text-primary);
    margin-bottom: 0;
    margin-right: 3px
}

.signle-comment .comment-header span {
    font-size: 14px;
    font-weight: 400;
    color: var(--text-secondary)
}

.signle-comment .comment-body p {
    margin-bottom: 20px
}

.signle-comment .comment-body .reply-btn {
    font-size: 14px;
    font-weight: 500;
    color: var(--text-primary);
    border: 1px solid var(--color-border);
    padding: 4px 18px;
    border-radius: 30px;
    transition: .4s ease;
    background-color: var(--color-white);
}

.signle-comment .comment-body .reply-btn:hover {
    background-color: var(--color-primary);
    color: var(--color-white);
    border: 1px solid var(--color-primary)
}

.recent-blog-list li {
    margin-bottom: 20px
}

.recent-blog-list li:last-child {
    margin-bottom: 0
}

.recent-blog-item {
    display: flex;
    align-items: flex-start;
    flex-wrap: nowrap;
    gap: 10px
}

.recent-blog-item .image {
    max-width: 90px;
    border-radius: 0px;
    overflow: hidden
}

.recent-blog-item .content h6 {
    font-size: 16px;
    margin-bottom: 5px
}

@media(max-width: 767px) {
    .recent-blog-item .content h6 {
        font-size: 14px
    }
}

.recent-blog-item .content h6 a {
    color: inherit
}

.recent-blog-item .content span {
    font-size: 12px
}

.sidebar-widget {
    margin-bottom: 40px;
    background-color: var(--bg-light);
    padding: 30px 30px
}
.sidebar-widget:last-child {
    margin-bottom: 0px;
}

@media(min-width: 992px)and (max-width: 1199px) {
    .sidebar-widget {
        padding: 25px
    }
}

@media(max-width: 991px) {
    .sidebar-widget {
        padding: 20px
    }
}

.sidebar-widget .sidebar-logo {
    max-width: 150px;
    margin-left: 0;
    margin-right: auto;
    margin-bottom: 30px
}

.sidebar-widget .widget-title {
    font-size: 22px;
    margin-bottom: 25px;
    line-height: 1;
    position: relative
}

@media (max-width: 768px) {
    .sidebar-widget .widget-title {
        font-size: 20px;
    }
}

.sidebar-widget ul.tag-list {
    display: flex;
    justify-content: start;
    flex-wrap: wrap;
    list-style: none;
    margin-bottom: 0;
    gap: 12px
}

.sidebar-widget ul.tag-list li a {
    font-size: 14px;
    font-weight: 500;
    transition: all .4s ease;
    padding: 3px 14px;
    color: var(--text-primary);
    border: 1px solid var(--color-border);
    background-color: var(--color-white);
}

.sidebar-widget ul.tag-list li a:hover {
    color: var(--color-white);
    border: 1px solid var(--color-primary);
    background-color: var(--color-primary);
}

.sidebar-widget ul.grid-list li {
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center
}

.sidebar-widget ul.grid-list li:last-child {
    margin-bottom: 0
}

.sidebar-widget ul.grid-list li span {
    display: inline-block;
    line-height: 1
}

.sidebar-widget ul.grid-list li span:first-child {
    font-size: 16px;
    color: var(--text-primary)
}

.sidebar-widget ul.grid-list li span:last-child {
    font-size: 20px;
    font-weight: 500;
    color: var(--text-primary)
}

.sidebar-widget ul.follow-list {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid var(--color-border)
}

.sidebar-widget ul.follow-list li {
    display: inline-block;
    text-align: center
}

.sidebar-widget ul.follow-list li span {
    display: inline-block;
    font-size: 15px;
    color: var(--text-secondary);
    line-height: 1
}

.filter-area {
    position: relative;
    margin-bottom: 20px
}

.filter-area input,
.filter-area textarea {
    padding: 8px 15px;
    font-size: 14px;
    height: 45px
}

.filter-area select {
    padding: 8px 15px;
    font-size: 14px;
    color: var(--text-secondary);
    height: 45px
}

.filter-area button {
    padding: 13px 24px !important;
    height: 45px
}

.arrows-style-1 .swiper-arrow {
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    border-radius: 50%;
    transition: .4s ease;
    opacity: 1;
    border: 1px solid var(--color-primary)
}

@media(max-width: 767px) {
    .arrows-style-1 .swiper-arrow {
        width: 40px;
        height: 40px
    }
}

.arrows-style-1 .swiper-arrow:hover {
    background-color: var(--color-primary)
}

.arrows-style-1 .swiper-arrow:hover i {
    color: var(--color-white);
    transition: .4s ease
}

.arrows-style-1 .swiper-arrow i {
    font-size: 22px;
    color: var(--color-primary);
    vertical-align: middle;
    line-height: 1
}

.service-section {
    position: relative;
    z-index: 1;
    overflow: hidden;
}

.service-title-wrap .nav-link {
    font-size: 30px;
    font-weight: 500;
    color: var(--text-primary);
    transition: .5s ease;
    padding: 18px 0;
    border-top: 1px solid var(--color-border);
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-transform: uppercase;
    border-radius: 0px
}

@media(min-width: 1200px)and (max-width: 1399px) {
    .service-title-wrap .nav-link {
        font-size: 24px;
        padding: 12px 0
    }
}

@media(min-width: 992px)and (max-width: 1199px) {
    .service-title-wrap .nav-link {
        font-size: 22px;
        padding: 5px 0
    }
}

@media(max-width: 991px) {
    .service-title-wrap .nav-link {
        font-size: 20px;
        padding: 10px 0
    }
}

.service-title-wrap .nav-link:hover i {
    transform: rotate(0)
}

.service-title-wrap .nav-link i {
    transform: rotate(45deg);
    font-size: 50px;
    font-weight: bold;
    transition: .4s ease
}

@media(max-width: 991px) {
    .service-title-wrap .nav-link i {
        font-size: 36px
    }
}

.service-title-wrap .nav-link:last-child {
    border-bottom: 1px solid var(--color-border)
}

.service-title-wrap .nav-link.active {
    background-color: unset;
    color: var(--color-primary)
}

.faq-wrap.style-border .accordion-item {
    box-shadow: unset;
    border-bottom: 1px solid var(--color-border)
}

.faq-wrap.style-border .accordion-item:first-child {
    border-top: 1px solid var(--color-border)
}

.faq-wrap.style-md .accordion-button {
    padding: 20px 30px 20px 0;
    font-size: 18px
}

@media (max-width: 768px){
    .faq-wrap.style-md .accordion-button {
        font-size: 16px
    }
}

.faq-wrap .accordion-item {
    margin-bottom: 0px;
    border: none;
    background-color: unset
}

.faq-wrap .accordion-item:last-child {
    margin-bottom: 0
}

.faq-wrap .accordion-button {
    font-weight: 400;
    font-size: 22px;
    background: rgba(0, 0, 0, 0);
    color: var(--text-primary);
    font-family: var(--font-merriw);
    padding: 30px 30px 30px 0px;
    padding-right: 40px;
    position: relative;
    transition: .4s ease-in;
    line-height: 1.6;
    border: unset
}

@media(min-width: 992px)and (max-width: 1199px) {
    .faq-wrap .accordion-button {
        font-size: 20px
    }
}

@media(min-width: 768px)and (max-width: 991px) {
    .faq-wrap .accordion-button {
        font-size: 20px
    }
}

@media(max-width: 767px) {
    .faq-wrap .accordion-button {
        font-size: 18px
    }
}

.faq-wrap .accordion-button i {
    margin-right: 15px;
    font-size: 18px
}

@media(max-width: 767px) {
    .faq-wrap .accordion-button {
        padding: 20px 55px 20px 20px
    }
}

.faq-wrap .accordion-button:hover {
    color: var(--color-primary)
}

.faq-wrap .accordion-button:hover::after {
    color: var(--color-primary)
}

.faq-wrap .accordion-button:focus {
    z-index: unset;
    border-color: unset;
    outline: 0;
    background: rgba(0, 0, 0, 0);
    color: unset;
    box-shadow: unset
}

.faq-wrap .accordion-button::after {
    flex-shrink: 0;
    width: unset;
    height: unset;
    margin-left: auto;
    background-image: none;
    background-repeat: unset;
    background-size: unset;
    content: "";
    font-family: "Bootstrap-icons" !important;
    font-size: 20px;
    position: absolute;
    right: 0px;
    top: 50%;
    transform: translateY(-50%);
    transition: unset;
    color: var(--text-primary);
    border-radius: 50%;
    transition: .6s ease
}
@media (max-width: 768px){
    .faq-wrap .accordion-button::after {
        font-size: 18px;
    }
}

.faq-wrap .accordion-button:not(.collapsed)::after {
    background-image: none;
    transform: unset;
    font-family: bootstrap-icons !important;
    content: "";
    top: 50%;
    transform: translateY(-50%);
    color: var(--color-primary)
}

.faq-wrap .accordion-body {
    padding: 0px 0px 30px 0px
}

.faq-wrap .accordion-body p {
    font-weight: 300;
    font-size: 16px;
    text-align: left;
    color: var(--text-secondary) !important
}

.faq-wrap .accordion-button:not(.collapsed) {
    box-shadow: unset;
    color: var(--color-primary);
    background: unset
}

.faq-wrap .accordion-item:first-of-type {
    border-top-left-radius: 0;
    border-top-right-radius: 0
}

.faq-wrap .accordion-item:last-of-type {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0
}

.today-currency-section {
    position: relative;
    padding-left: 10%;
    padding-right: 10%
}

@media(max-width: 991px) {
    .today-currency-section {
        padding-left: 0;
        padding-right: 0
    }
}

.coin-flip {
    position: absolute;
    top: 20px;
    right: 50px;
    width: 120px;
    height: 120px;
    margin: auto;
    transform-style: preserve-3d;
    animation: fly 1.5s ease-in-out 0s infinite alternate;
    filter: drop-shadow(-14px 86px 26px rgba(0, 0, 0, 0.2))
}

@media(max-width: 991px) {
    .coin-flip {
        display: none;
        visibility: hidden
    }
}

.coin-flip .coin-heads,
.coin-flip .coin-tails {
    backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
    -webkit-clip-path: circle(46%);
    clip-path: circle(46%)
}

.coin-flip .coin-heads {
    animation: flip-head 2s linear 0s infinite alternate
}

.coin-flip .coin-tails {
    animation: flip-tail 2s linear 0s infinite alternate
}

@keyframes flip-head {

    0%,
    100% {
        transform: rotateY(0deg)
    }

    50% {
        transform: rotateY(180deg)
    }
}

@keyframes flip-tail {

    0%,
    100% {
        transform: rotateY(180deg)
    }

    50% {
        transform: rotateY(0deg)
    }
}

.trade-graph {
    position: relative
}

.tradingview-widget-container {
    padding: 0px;
    background-color: var(--color-white);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, .2)
}

.introduction-wrapper {
    text-align: left
}

.introduction-wrapper h4 {
    margin-bottom: 18px;
    color: var(--color-white)
}

.introduction-wrapper ul {
    margin-top: 10px
}

.introduction-wrapper ul li {
    font-size: 18px;
    font-weight: 300;
    margin-bottom: 15px;
    color: var(--color-white);
    opacity: .8;
}

@media(max-width: 767px) {
    .introduction-wrapper ul li {
        font-size: 16px
    }
}

.introduction-wrapper ul li:last-child {
    margin-bottom: 0
}

.introduction-wrapper ul li i {
    color: var(--color-primary);
    margin-right: 7px;
    vertical-align: middle;
    font-size: 20px
}

.advertise-section {
    overflow: hidden;
    position: relative;
    z-index: 1;
}

@media(max-width: 1199px) {
    .advertise-section::after {
        width: 100%
    }
}

.advertise-slider-wrap{
    margin-right: 25px;
}

.advertise-slider img {
    min-height: 440px;
    object-fit: cover;
    border-radius: 10px;
    border: 2px solid var(--color-border);
}

@media(max-width: 991px) {
    .advertise-slider img {
        min-height: auto
    }
}

.crypto-details .title {
    display: flex;
    flex-direction: row;
    align-items: center
}

.crypto-details .title .icon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 12px
}

.crypto-details .title h2 span {
    display: inline-block;
    font-size: 20px;
    color: var(--text-secondary);
    font-weight: 400;
    margin-left: 5px
}

.crypto-details select {
    border-radius: 30px !important;
    background-color: var(--color-white);
}

.crypto-details .graph {
    margin-bottom: 40px
}

.details-header{
    padding: 25px;
    background-color: var(--bg-light);
}

.currency-rate h3 {
    font-size: 23px;
    display: inline-flex
}

.currency-rate span {
    display: inline-block;
    font-size: 18px;
    font-weight: 500;
    margin-left: 10px
}
.user-meta-info h6 {
    font-size: 14px;
}
.user-meta-info p {
    margin-bottom: 0
}

.conversion-section {
    position: relative
}

.trading-section {
    background-color: var(--bg-light)
}

.market-widget {
    background-color: var(--color-white);
    padding: 20px
}

.market-widget.left-market {
    height: auto;
    max-height: 810px;
    overflow: auto
}

@media(max-width: 767px) {
    .market-widget.left-market {
        max-height: 400px
    }
}

.market-widget .input-single {
    margin-bottom: 15px
}

.market-widget .input-single input,
.market-widget .input-single textarea {
    height: 40px;
    line-height: 40px;
    border: 1px solid var(--color-border)
}

.market-widget .profit-card {
    text-align: center;
    padding-top: 10px;
    padding-bottom: 20px
}

.market-widget .profit-card p {
    margin-bottom: 0px;
    font-size: 18px
}

.market-widget .profit-card .percent span {
    display: inline-block;
    font-weight: 500;
    line-height: 1;
    color: var(--color-success);
    font-size: 32px
}

.market-widget .profit-card .percent sub {
    font-size: 18px;
    font-weight: 500;
    color: var(--color-success)
}

.market-widget .search-with-input {
    position: relative
}

.market-widget .search-with-input input,
.market-widget .search-with-input textarea {
    height: 40px;
    line-height: 40px;
    padding-left: 35px;
    border: 1px solid var(--color-border)
}

.market-widget .search-with-input input::-moz-placeholder,
.market-widget .search-with-input textarea::-moz-placeholder {
    -moz-transition: .3s ease;
    transition: .3s ease
}

.market-widget .search-with-input input::placeholder,
.market-widget .search-with-input textarea::placeholder {
    transition: .3s ease
}

.market-widget .search-with-input input:focus,
.market-widget .search-with-input textarea:focus {
    border: 1px solid var(--color-primary)
}

.market-widget .search-with-input input:focus::-moz-placeholder,
.market-widget .search-with-input textarea:focus::-moz-placeholder {
    color: var(--color-primary)
}

.market-widget .search-with-input input:focus::placeholder,
.market-widget .search-with-input textarea:focus::placeholder {
    color: var(--color-primary)
}

.market-widget .search-with-input i {
    font-size: 15px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 10px;
    vertical-align: middle
}

.market-widget .market-slider-wrapper {
    margin: 25px 0;
    position: relative
}

.market-widget .market-slider-wrapper .swiper-arrow {
    width: 15px;
    height: 35px;
    text-align: center;
    line-height: 35px;
    z-index: 1;
    background-color: var(--color-dark-2);
}

.market-widget .market-slider-wrapper .swiper-arrow i {
    font-size: 14px;
    color: var(--color-white)
}

.market-widget .market-slider-wrapper .swiper-arrow.market-prev {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -20px
}

.market-widget .market-slider-wrapper .swiper-arrow.market-next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: -20px
}

.market-widget .market-list-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between
}

.market-widget .market-list-header>span {
    display: inline-block;
    font-size: 16px;
    font-weight: 400;
    color: var(--color-white);
}

.market-widget ul.market-list {
    margin-top: 8px;
    width: 100%
}

.market-widget ul.market-list.order-book {
    height: auto;
    max-height: 276px;
    overflow: auto
}

@media(max-width: 767px) {
    .market-widget ul.market-list.order-book {
        max-height: 100%;
        overflow: unset
    }
}

.market-widget ul.market-list li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    font-size: 14px;
    margin-bottom: 8px;
    font-weight: 300
}

.market-widget ul.market-list li:last-child {
    margin-bottom: 0
}

.market-widget ul.market-list li a {
    color: var(--text-primary)
}

.market-widget ul.market-list li span {
    display: inline-block;
    font-size: 14px;
    min-width: 40px
}

.market-widget ul.market-list li span:last-child {
    min-width: 20px
}

.market-details-header {
    background-color: var(--color-white);
    padding: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 5px;
    margin-bottom: 8px;
    flex-wrap: wrap
}

@media(max-width: 767px) {
    .market-details-header .title {
        width: 100%
    }
}

.market-details-header .title h1 {
    font-size: 22px
}

@media(max-width: 767px) {
    .market-details-header .title h1 {
        font-size: 20px
    }
}

.market-details-header .header-item span {
    display: inline-block;
    font-size: 14px;
    font-weight: 300
}

.market-details-header .header-item p {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 0;
    color: var(--text-primary);
    line-height: 1
}

.tradingview-widget-container {
    box-shadow: unset;
    max-width:100%;
}

.card--title h5 {
    margin-bottom: 10px;
    font-size: 18px
}

/* Feature section */

.fearure-wrapper{
    background-color: rgba(255,255,255,0.05);
    padding: 30px;
}

@media (max-width:768px) {
    .fearure-wrapper{
        background-color: rgba(255,255,255,0.05);
        padding: 20px;
    }
}
.feature-single{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
    padding: 20px 10px;
}
.feature-single .icon{
    flex-shrink: 0;
}
.feature-single .icon i{
    color: var(--color-primary);
    font-size: 30px;
}
@media (max-width:768px) {
    .feature-single .icon i{
        font-size: 24px;
    }
}
.feature-single .content{
    flex-grow: 1;
}

.feature-single .content h4{
    color: var(--color-white);
    margin-bottom: 8px;
    font-size: 24px;
    font-weight: 400;
    line-height: 1.25;
}
@media (max-width:768px) {
    .feature-single .content h4{
        font-size: 20px;
    }
}
.feature-single .content p{
    color: var(--color-white);
    opacity: 0.65;
    margin-bottom: 0;
}

.dropdown-language select{
    border: none;
    font-weight: 400;
    color: var(--color-white);
}
.dropdown-language select option{
    color: var(--color-white);
    background-color: var(--text-primary);
}
.dropdown-language select option:hover{
    background-color: rgba(255,255,255,0.8) !important;
}

/* Dark Card */

.dark--card{
    background-color: var(--color-dark-2);
}
.dark--card .card--title h5{
    color: var(--color-white);
}
.dark--card .input-single label{
    color: var(--color-white);
    opacity: 0.7;
}
.dark--card .input-single textarea{
    border: 1px solid rgba(255,255,255,0.3);
    font-weight: 300;
}
.dark--card .input-single input{
    border: 1px solid rgba(255,255,255,0.3);
    color: var(--color-white);
    font-weight: 300;
}
.dark--card .input-single select{
    color: var(--color-white);
    font-weight: 300;
    border: 1px solid rgba(255,255,255,0.3);
}
.dark--card .profit-card p{
    color: var(--color-white);
}

.dark--card .nav-style-five ul.nav li a.nav-link {
    background-color: rgba(255,255,255,0.15);
    color: var(--color-white);
    border: 1px solid rgba(255,255,255,0.15);
}
.dark--card .nav-style-five ul.nav li a.nav-link:hover {
    background-color:var(--color-primary);
    color: var(--color-white);
    border: 1px solidvar(--color-primary);
}
.dark--card .nav-style-five ul.nav li a.nav-link.active {
    background-color:var(--color-primary);
    color: var(--color-white);
    border: 1px solidvar(--color-primary);
}

.tradingview-widget-container{
    background-color: var(--color-dark-2);
}

.cookie {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 40px;
    width: 60%;
    margin: 0 auto;
    padding: 25px;
    position: fixed;
    isolation: isolate;
    z-index: 200;
    left: 50%;
    bottom: 50px;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    background-color: var(--color-white);
    border: 2px solid var(--color-border);
    border-radius: 12px;
}
@media (max-width: 1199px) {
    .cookie {
        width: 90%;
    }
}
@media (max-width: 991px) {
    .cookie {
        width: 95%;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: end;
        -ms-flex-align: end;
        align-items: flex-end;
        gap: 25px;
        padding: 20px;
    }
}
.cookie .cookie-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: self-start;
    -ms-flex-align: self-start;
    align-items: self-start;
    gap: 20px;
}
.cookie .cookie-content .cookie-icon {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50px;
    flex: 0 0 50px;
    width: 50px;
}
@media (max-width: 576px) {
    .cookie .cookie-content .cookie-icon {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 35px;
        flex: 0 0 35px;
        width: 35px;
    }
}
.cookie .cookie-content .cookie-icon svg {
    width: 100%;
    fill: var(--text-primary);
}
.cookie .cookie-action {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 20px;
}
@media (max-width: 576px) {
    .cookie .cookie-action {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }
}
@media screen and (max-width: 424px) {
    .cookie .cookie-action > button {
        width: 100%;
    }
}

.input-single select option {
    background: var(--color-dark-2);
}



@media (max-width: 991px){
  .form-section{
    padding: 60px 20px;
  }
  .form-wrapper2 {
        padding: 40px 25px;
        background: #fff;
    }
}

.profit-calc-section{
    position: relative;
    z-index: 1;
}

.profit-calc-section .linear-big {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 10%;
    width: 520px;
    height: 520px;
    border-radius: 50%;
    background-color: var(--color-primary-light);
    filter: blur(40px);
    z-index: -1;
}
.profit-calc-wrapper{
    background-color: rgba(255,255,255,0.09);
    border-radius: 30px;
    padding: 40px;
    position: relative;
}

.profit-content{
    margin-top: 30px;
}

.profit-content h5{
    color: var(--color-white);
    margin-bottom: 10px;
}
.profit-content h5 span{
    display: inline-block;
    color: var(--color-primary);
}

.profit-content p{
    color: var(--color-white);
    opacity: 0.8;
}
.profit-form label{
    color: var(--color-white);
    margin-bottom: 8px;
    font-size: 15px;
}
.profit-form input{
    color: var(--color-white);
    border: 1px solid rgba(255,255,255,0.45);
}
.profit-form select{
    color: var(--color-white);
    border: 1px solid rgba(255,255,255,0.45);
    height: 50px;
}
.profit-form select option{
    color: var(--text-primary);
}

.profit-list li{
    margin-bottom: 20px;
    padding-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(255,255,255,0.15);
}
.profit-list li:last-child{
    margin-bottom: 0px;
}

.profit-list li span{
    color: var(--color-white);
}
.profit-list li span:last-child{
    font-weight: 500;
}
.profit-subtitle{
    font-size: 24px;
}

@media (max-width: 991px) {
    .profit-calc-section .linear-big {
        width: 300px;
        height: 300px;
    }
    .profit-calc-wrapper{
        padding: 30px;
    }
    .profit-subtitle{
        font-size: 20px;
    }
}

.invest-card {
    background-color: var(--color-white);
    padding: 20px 20px 20px 65px;
    background: linear-gradient(-135deg, var(--color-primary-light-2), #fff);
    border: 1px solid var(--color-border);
    border-radius: 20px;
    transition: 0.4s ease;
    position: relative;
    margin-left: 45px;
}

.invest-card:hover{
    border: 1px solid var(--text-primary);
}

.invest-card:hover .interest{
    background: linear-gradient(90deg,var(--text-primary), var(--color-primary));
}


.invest-card .interest {
    transition: 0.4s ease;
    width: 90px;
    height: 90px;
    background: linear-gradient(90deg,var(--text-primary), var(--text-primary));
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -45px;
}
.invest-card .interest h4{
    color: var(--color-white);
    margin-bottom: 0px;
    font-size: 22px;
}
.invest-card .interest span{
    color: var(--color-white);
    display: inline-block;
    font-size: 16px;
    line-height: 1;
}

.info-item span{
    display: block;
}
.info-item span:first-child{
    font-weight: 500;
}



